import React, { useState } from "react";
import "./App.css";
import { FaLessThan } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { IoNotifications } from "react-icons/io5";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { Select, Input, Upload, message, Space, Modal, Typography } from "antd";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { MdClear } from "react-icons/md";
import { ApiEndPoints } from "./Api/Apiendpoints";
import { postProducts } from "./Api/Apicalls";
import { MdOutlineCloudUpload } from "react-icons/md";

// Custom TabPanel for Tabs component
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
const { Dragger } = Upload;

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// Accessible properties for Tabs
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  padding: "20px",
  borderRadius: "10px",
  p: 5,
};

export default function Addnew() {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [productData, setProductData] = useState({
    category: "",
    productId: "",
    productName: "",
    weight: null,
    price: "",
    description: "",
    deliveryInfo: "",
    nutritionalValues: "",
    metaHeading:"",
    metaContent:"",
    slug:""
  });
  const navigate = useNavigate();

  // Handle tab changes
  const HandleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Handle modal open and close
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content) => {
    messageApi.open({
      type: 'success',
      content: content,
    });
  };

  const errormessage = (content) => {
    messageApi.open({
      type: 'error',
      content: content,
    });
  };

  // Handle form changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductData({ ...productData, [name]: value });
  };

  const handleSelectChange = (value, name) => {
    setProductData({ ...productData, [name]: value });
  };

  const props = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      // Store the selected file in the state
      setFile(file);
      setImageUrl(URL.createObjectURL(file));
      return false;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // Handle product posting
  // const handlePostProduct = async () => {
  //   if (!file) {
  //     message.error("No file selected");
  //     return;
  //   }

  //   // const base64File = await convertToBase64(file);
  //   try {



  //     // Create a new FormData object
  //     const formData = new FormData();
  //     // Append form fields to FormData
  //     formData.append("category", productData.category);
  //     formData.append("productId", productData.productId);
  //     formData.append("name", productData.productName);
  //     formData.append("minWeightInGrams", productData.weight);
  //     formData.append("price", productData.price);
  //     formData.append("description", productData.description);
  //     formData.append("deliveryInfo", productData.deliveryInfo);
  //     formData.append("nutritionalInfo", productData.nutritionalValues);
  //     formData.append("image", file);
  //     formData.append("rating", 4);
  //     formData.append("metaHeading", productData.metaHeading);
  //     formData.append("metaContent", productData.metaContent);
  //     console.log(formData,"formData");
      
      
  //     // Make the API request
  //     const result = await postProducts(ApiEndPoints("postProduct"), formData);
  //     setProductData({
  //       category: "",
  //       productId: "",
  //       productName: "",
  //       weight: null,
  //       price: "",
  //       description: "",
  //       deliveryInfo: "",
  //       nutritionalValues: "",
  //       metaContent:"",
  //       metaHeading:""
  //     });
  //     setFile(null);
  //     navigate("/products");
  //     successmessage("Product added...")
  //   } catch (error) {
  //     if (error.response && error.response.data && error.response.data.message) {
  //       errormessage(error.response.data.message);
  //     } else if (error.message) {
  //       errormessage(error.message);
  //     } else {
  //       errormessage("Something went wrong");
  //     }    }
  // };


  // Handle product posting
const handlePostProduct = async () => {
  // List of required fields
  const requiredFields = [
    { value: productData.category, name: "Category" },
    { value: productData.productName, name: "Product Name" },
    { value: productData.weight, name: "Weight" },
    { value: productData.price, name: "Price" },
    { value: productData.description, name: "Description" },
    { value: productData.deliveryInfo, name: "Delivery Info" },
    { value: productData.nutritionalValues, name: "Nutritional Info" },
    { value: productData.metaHeading, name: "Meta Heading" },
    { value: productData.metaContent, name: "Meta Content" },
    { value: productData.slug, name: "slug" },
    { value: file, name: "File" },
  ];

  // Check for missing fields
  const missingFields = requiredFields.filter(
    (field) => !field.value || field.value === ""
  );

  if (missingFields.length > 0) {
    const missingFieldNames = missingFields.map((field) => field.name).join(", ");
    message.error(`Please fill out the following fields: ${missingFieldNames}`);
    return;
  }

  try {
    // Create a new FormData object
    const formData = new FormData();
    // Append form fields to FormData
    formData.append("category", productData.category);
    formData.append("productId", productData.productId);
    formData.append("name", productData.productName);
    formData.append("minWeightInGrams", productData.weight);
    formData.append("price", productData.price);
    formData.append("description", productData.description);
    formData.append("deliveryInfo", productData.deliveryInfo);
    formData.append("nutritionalInfo", productData.nutritionalValues);
    formData.append("image", file);
    formData.append("rating", 4);
    formData.append("metaHeading", productData.metaHeading);
    formData.append("metaContent", productData.metaContent);
    formData.append("slug", productData.slug);
    console.log(formData, "formData");

    // Make the API request
    const result = await postProducts(ApiEndPoints("postProduct"), formData);
    setProductData({
      category: "",
      productId: "",
      productName: "",
      weight: null,
      price: "",
      description: "",
      deliveryInfo: "",
      nutritionalValues: "",
      metaContent: "",
      metaHeading: "",
      slug:""
    });
    setFile(null);
    navigate("/products");
    successmessage("Product added...");
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      errormessage(error.response.data.message);
    } else if (error.message) {
      errormessage(error.message);
    } else {
      errormessage("Something went wrong");
    }
  }
};


  return (
    <div className="mx-3 px-4">
      {contextHolder}
      <div style={{ padding: "20px 20px" }}>
        <h3>
          <Link to="/orders" activeStyle>
            <FaLessThan style={{ color: "#0d354f" }} />
          </Link>
          &nbsp;New Products
        </h3>
        <div className="product-combine">
          <div className="addnew-contain pb-2">
            <div style={{ marginTop: "40px" }}>
              <label>Select categories <span style={{color:"red"}}>*</span></label>
              <br />
              <Space wrap>
                <Select
                  name="category"
                  value={productData.category}
                  className="select-pending product-search-new"
                  onChange={(value) => handleSelectChange(value, "category")}
                  options={[
                    { value: "chicken", label: "Chicken" },
                    { value: "mutton", label: "Mutton" },
                  ]}
                />
              </Space>
            </div>
            {/* <div style={{ marginTop: "10px" }}>
              <label>Product Id</label>
              <br />
              <Input
                name="productId"
                value={productData.productId}
                onChange={handleInputChange}
                style={{ width: "400px", height: "35px", color: "black" }}
                // placeholder="1000101"
                className="select-pending product-search-new"
              />
            </div> */}

            <div style={{ marginTop: "10px" }}>
              <label>Product Name <span style={{color:"red"}}>*</span></label>
              <br />
              <Input
                name="productName"
                onChange={handleInputChange}
                style={{ width: "400px", height: "35px", color: "black" }}
                placeholder=" "
                className="select-pending product-search-new"
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <label>Image (Recommanded 292*400 px) <span style={{color:"red"}}>*</span></label>
              <br />
              <div className="">
                <Dragger {...props} className="dragger-box">
                  <p className="ant-upload-drag-icon mb-0">
                    <MdOutlineCloudUpload
                      style={{ color: "#0D354F", fontSize: "3rem" }}
                    />
                  </p>
                  <p className="ant-upload-text mb-0">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              </div>
              <div style={{ width: "400px" }} className="pt-3">
                {imageUrl && (
                  <div style={{ marginBottom: "10px" }}>
                    <img
                      src={imageUrl}
                      alt="Selected"
                      style={{ width: "20%" }}
                    />
                  </div>
                )}
              </div>
            </div>

            <br />
            <div
              style={{
                display: "flex",
                gap: "25px",
                alignItems: "end",
              }}
            >
              <div>
                <label>Weight (Min) <span style={{color:"red"}}>*</span></label>
                <br />
                {/* <Select
                  name="weight"
                  value={productData.weight}
                  style={{ width: 120 }}
                  className="select-pending product-search-gram"
                  onChange={(value) => handleSelectChange(value, "weight")}
                  options={[
                    { value: 250, label: "250 g" },
                    { value: 500, label: "500 g" },
                    { value: 750, label: "750 g" },
                    { value: 1000, label: "1000 g" },
                  ]}
                /> */}
                   <Input
                  name="weight"
                  value={productData.weight}
                  onChange={handleInputChange}
                  style={{ width: "115px", height: "35px", color: "black" }}
                  placeholder=" "
                  className="product-search-gram"
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <label>Price <span style={{color:"red"}}>*</span></label>
                <br />
                <Input
                  name="price"
                  value={productData.price}
                  onChange={handleInputChange}
                  style={{ width: "115px", height: "35px", color: "black" }}
                  placeholder=" "
                  className="product-search-gram"
                />
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
            <label>Piece <span style={{color:"red"}}>*</span></label>
                <br />
                <Input
                name="piece"
                onChange={handleInputChange}
                style={{ width: "400px", height: "35px", color: "black" }}
                placeholder=" "
                className="select-pending product-search-new"
              />
            </div>
            <div style={{ marginTop: "10px" }}>
            <label>Meta Heading <span style={{color:"red"}}>*</span></label>
                <br />
                <Input
                name="metaHeading"
                onChange={handleInputChange}
                style={{ width: "400px", height: "35px", color: "black" }}
                placeholder=" "
                className="select-pending product-search-new"
              />
            </div>
            <div style={{ marginTop: "10px" }}>
            <label>Meta Content <span style={{color:"red"}}>*</span></label>
                <br />
                <textarea
                    name="metaContent"
                    value={productData.metaContent}
                    onChange={handleInputChange}
                    className="text-area p-2"
                    rows={5}
                  ></textarea>
            </div>
            <div style={{ marginTop: "10px" }}>
            <label>URL format(Eg:buy-mutton-kidney) <span style={{color:"red"}}>*</span></label>
                <br />
                <Input
                name="slug"
                onChange={handleInputChange}
                style={{ width: "400px", height: "35px", color: "black" }}
                placeholder=" "
                className="select-pending product-search-new"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Box sx={{ width: "100%" }} className="product-search-new-tab">
                <Box>
                  <Tabs
                    value={value}
                    onChange={HandleChange}
                    aria-label="product tabs"
                  >
                    <Tab label="Product Description" {...a11yProps(0)} />
                    <Tab label="Delivery Information" {...a11yProps(1)} />
                    <Tab label="Nutritional Values" {...a11yProps(2)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <textarea
                    name="description"
                    value={productData.description}
                    onChange={handleInputChange}
                    className="text-area p-2"
                    rows={5}
                  ></textarea>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <textarea
                    name="deliveryInfo"
                    value={productData.deliveryInfo}
                    onChange={handleInputChange}
                    className="text-area p-2"
                    rows={5}
                  ></textarea>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <textarea
                    name="nutritionalValues"
                    value={productData.nutritionalValues}
                    onChange={handleInputChange}
                    className="text-area p-2"
                    rows={5}
                  ></textarea>
                </CustomTabPanel>
              </Box>
            </div>
            <div
              style={{
                marginTop: "40px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                style={{
                  color: "white",
                  backgroundColor: "#0d354f",
                  border: "none",
                  padding: "5px 50px",
                  borderRadius: "5px",
                }}
                onClick={handlePostProduct}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
