import React, { useState, useEffect } from "react";
import "./App.css";
import { Link } from "react-router-dom";
import { IoNotifications } from "react-icons/io5";
import { FaBoxOpen, FaCheckCircle } from "react-icons/fa";
// import Card from "react-bootstrap/Card";
import { FaArrowUp, FaArrowDownLong } from "react-icons/fa6";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FaSortDown } from "react-icons/fa6";
import { Select, Input, Upload, Flex, message, Space, DatePicker } from "antd";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Chart } from "primereact/chart";
import "primereact/resources/themes/saga-blue/theme.css"; // Choose your theme
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Modal from "@mui/material/Modal";
import { MdClear } from "react-icons/md";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { allOrders, getReport } from "./Api/Apicalls";
import { ApiEndPoints } from "./Api/Apiendpoints";
import moment from "moment";
import { API_URL } from "../src/Api/Config";

const weekFormat = "DD/MM/YY";

const customWeekStartEndFormat = (value) =>
  `${dayjs(value).startOf("week").format(weekFormat)} ~ ${dayjs(value)
    .endOf("week")
    .format(weekFormat)}`;

export default function Home() {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [isleavereq, setIsLeavereq] = useState(false);
  const handleLeavereqOpen = () => setIsLeavereq(true);
  const handleLeavereqclose = () => setIsLeavereq(false);

  const onChange = (value) => {
    console.log("selected", value);
  };

  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      datasets: [
        {
          data: [9, 18, 13, 34, 30, 24, 50],
          fill: true,
          borderColor: documentStyle.getPropertyValue("--blue-800"),
          backgroundColor: documentStyle.getPropertyValue("--bluegray-100"),
          tension: 0.4,
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  // CIRCLE

  const [chartDataS, setChartDataS] = useState({});
  const [chartOption, setChartOption] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const data = {
      labels: ["Chicken Keema(30)", "Chicken drum(50)", "Chicken breast(20)"],
      datasets: [
        {
          data: [150, 50, 100],
          backgroundColor: [
            documentStyle.getPropertyValue("--blue-900"),
            documentStyle.getPropertyValue("--blue-800"),
            documentStyle.getPropertyValue("--blue-200"),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue("--blue-900"),
            documentStyle.getPropertyValue("--blue-800"),
            documentStyle.getPropertyValue("--blue-200"),
          ],
        },
      ],
    };
    const options = {
      cutout: "70%",
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    setChartDataS(data);
    setChartOption(options);
  }, []);

  const [data, setData] = useState([]);

  const getAllorders = async () => {
    try {
      const response = await allOrders(ApiEndPoints("getAllorders"));
  
      const filterTotalOrders = response.orders.filter((order) => {
        // Get the local date of the order
        const orderDate = new Date(order.createdAt);
        console.log(orderDate,"orderDate");
        
        const orderDateStr = orderDate.toLocaleDateString('en-CA'); 
  
        // Get today's local date
        const todayDate = new Date();
        const todayDateStr = todayDate.toLocaleDateString('en-CA');
  
        // Compare the local date strings
        return orderDateStr === todayDateStr;
      });

      const lastFiveOrders = filterTotalOrders.slice(0, 5);
  
      const sortedOrders = lastFiveOrders.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
  
      // Get the last 5 orders
      
      setData(sortedOrders);
    } catch (err) {
      console.error(err);
    }
  };
  

  const [todayReport, setTodaysReport] = useState(null);
  const [yesterdayReport, setYesterdayReport] = useState(null);
  const [percentageChanges, setPercentageChanges] = useState({});

  const getReports = async () => {
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let date = today.getDate();
    try {
      const response = await getReport(
        ApiEndPoints("report"),
        year,
        month,
        date
      );
      setTodaysReport(response.report);
      getYesterdayReports(year, month, date - 1);
    } catch (err) {
      console.error(err);
    }
  };

  const getYesterdayReports = async (year, month, date) => {
    try {
      const response = await getReport(
        ApiEndPoints("report"),
        year,
        month,
        date
      );
      setYesterdayReport(response.report);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (todayReport && yesterdayReport) {
      calculatePercentageChanges();
    }
  }, [todayReport, yesterdayReport]);

  const calculatePercentageChanges = () => {
    const changes = {
      ordersCount: calculateChange(
        todayReport.ordersCount,
        yesterdayReport.ordersCount
      ),
      cancelledOrdersCount: calculateChange(
        todayReport.cancelledOrdersCount,
        yesterdayReport.cancelledOrdersCount
      ),
      revenue: calculateChange(todayReport.revenue, yesterdayReport.revenue),
      bulkOrdersCount: calculateChange(
        todayReport.bulkOrdersCount,
        yesterdayReport.bulkOrdersCount
      ),
    };
    setPercentageChanges(changes);
  };

  const calculateChange = (todayValue, yesterdayValue) => {
    if (yesterdayValue === 0) {
      return todayValue === 0 ? 0 : 100; // 100% increase if yesterday is 0 and today is not 0
    }
    return ((todayValue - yesterdayValue) / yesterdayValue) * 100;
  };

  const formatChange = (change) => {
    const isIncrease = change > 0;
    const formattedChange = Math.abs(change).toFixed();

    if (change === 0) {
      return `0 % (Vs Yesterday)`;
    } else if (isIncrease) {
      return `+${formattedChange}% (Vs Yesterday)`;
    } else {
      return `-${formattedChange}% (Vs Yesterday)`;
    }
  };

  useEffect(() => {
    getAllorders();
    getReports();
  }, []);

  return (
    <div className="home-container mx-3">
      <div className="today-count" style={{ overflow: "hidden" }}>
        <h1 className="h1" style={{ padding: "0px 20px" }}>
          Today's
        </h1>

        <div className="today-count-box py-3">
          <div className="today-count-card">
            <div className="row" style={{ padding: "5px 25px" }}>
              <div className="col-lg-3 col-md-6 py-1">
                {/* CARD-1 */}
                <div
                  style={{
                    border: "1px solid #0d354f ",
                    display: "flex",
                    justifyContent: "space-evenly",
                    padding: "10px",
                    borderRadius: "15px",
                    height: "100%",
                  }}
                >
                  <div className="col-lg-6 d-flex justify-content-center align-items-center">
                    <div
                      style={{
                        width: "50px",
                        height: "50px",
                        backgroundColor: "#0d354f",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FaBoxOpen
                        style={{
                          color: "white",
                          fontSize: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex justify-content-space-center align-items-center">
                    <div>
                      <h5
                        className="mb-0"
                        style={{
                          color: "#0d354f",
                          fontSize: "1.7rem",
                        }}
                      >
                        {todayReport ? todayReport.ordersCount : ""}
                      </h5>
                      <p
                        className="mb-0"
                        style={{ fontSize: "15px", color: "#0d354f" }}
                      >
                        Total Orders
                      </p>
                      <div
                        style={{
                          fontSize: "10px",
                          display: "flex",
                          marginTop: "2px",
                        }}
                      >
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor:
                              percentageChanges.ordersCount < 0
                                ? "red"
                                : "limegreen",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className="me-1"
                        >
                          {percentageChanges.ordersCount < 0 ? (
                            // <FaArrowDownLong style={{ color: "white" }} />
                            <img
                              src="/assests/low-price.gif"
                              style={{ width: "30px" }}
                            />
                          ) : (
                            // <FaArrowUp style={{ color: "white" }} />
                            <img
                              src="/assests/low-price.gif"
                              style={{
                                width: "30px",
                                transform: "rotate(180deg)",
                              }}
                            />
                          )}
                        </div>
                        &nbsp;{" "}
                        <p className="mb-0 yesterday">
                          {formatChange(percentageChanges.ordersCount)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3  col-md-6 py-1">
                {/* CARD-3 */}
                <div
                  style={{
                    border: "1px solid #0d354f ",
                    display: "flex",
                    justifyContent: "space-evenly",
                    padding: "10px",
                    borderRadius: "15px",
                    height: "100%",
                  }}
                >
                  <div className="col-lg-6 d-flex justify-content-center align-items-center">
                    <div
                      style={{
                        width: "50px",
                        height: "50px",
                        backgroundColor: "#0d354f",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FaBoxOpen
                        style={{
                          color: "white",
                          fontSize: "30px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex justify-content-center align-items-center">
                    <div>
                      <h5
                        className="mb-0"
                        style={{
                          color: "#0d354f",
                          fontSize: "1.7rem",
                        }}
                      >
                        {todayReport ? todayReport.bulkOrdersCount : ""}
                      </h5>
                      <p
                        className="mb-0"
                        style={{ fontSize: "15px", color: "#0d354f" }}
                      >
                        Total Bulk Orders
                      </p>
                      <div
                        style={{
                          fontSize: "10px",
                          display: "flex",
                          marginTop: "2px",
                        }}
                      >
                        <p
                          style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor:
                              percentageChanges.bulkOrdersCount < 0
                                ? "red"
                                : "limegreen",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {percentageChanges.bulkOrdersCount < 0 ? (
                            // <FaArrowDownLong style={{ color: "white" }} />
                            <img
                              src="/assests/low-price.gif"
                              style={{ width: "30px" }}
                            />
                          ) : (
                            // <FaArrowUp style={{ color: "white" }} />
                            <img
                              src="/assests/low-price.gif"
                              style={{
                                width: "30px",
                                transform: "rotate(180deg)",
                              }}
                            />
                          )}
                        </p>
                        &nbsp;{" "}
                        <p className="mb-0 yesterday">
                          {formatChange(percentageChanges.bulkOrdersCount)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3  col-md-6 py-1">
                {/* CARD-3 */}
                <div
                  style={{
                    border: "1px solid #0d354f ",
                    display: "flex",
                    justifyContent: "space-evenly",
                    padding: "10px",
                    borderRadius: "15px",
                    height: "100%",
                  }}
                >
                  <div className="col-lg-6 d-flex justify-content-center align-items-center">
                    <div
                      style={{
                        width: "50px",
                        height: "50px",
                        backgroundColor: "#0d354f",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FaBoxOpen
                        style={{
                          color: "white",
                          fontSize: "30px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex justify-content-center align-items-center">
                    <div>
                      <h5
                        className="mb-0"
                        style={{
                          color: "#0d354f",
                          fontSize: "1.7rem",
                        }}
                      >
                        {todayReport ? todayReport.cancelledOrdersCount : ""}
                      </h5>
                      <p
                        className="mb-0"
                        style={{ fontSize: "15px", color: "#0d354f" }}
                      >
                        Total Cancelled
                      </p>
                      <div
                        style={{
                          fontSize: "10px",
                          display: "flex",
                          marginTop: "2px",
                        }}
                      >
                        <p
                          style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor:
                              percentageChanges.cancelledOrdersCount < 0
                                ? "red"
                                : "limegreen",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {percentageChanges.cancelledOrdersCount < 0 ? (
                            <img
                              src="/assests/low-price.gif"
                              style={{ width: "30px" }}
                            />
                          ) : (
                            <img
                              src="/assests/low-price.gif"
                              style={{
                                width: "30px",
                                transform: "rotate(180deg)",
                              }}
                            />
                          )}
                        </p>
                        &nbsp;
                        <p className="mb-0 yesterday">
                          {formatChange(percentageChanges.cancelledOrdersCount)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 py-1">
                {/* card-4 */}
                <div
                  style={{
                    border: "1px solid #0d354f ",
                    display: "flex",
                    justifyContent: "space-evenly",
                    padding: "10px",
                    borderRadius: "15px",
                    height: "100%",
                  }}
                >
                  <div className="col-lg-6 d-flex justify-content-center align-items-center">
                    <div
                      style={{
                        width: "50px",
                        height: "50px",
                        backgroundColor: "#0d354f",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FaBoxOpen
                        style={{
                          color: "white",
                          fontSize: "30px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex justify-content-center align-items-center">
                    <div>
                      <h5
                        className="mb-0"
                        style={{
                          color: "#0d354f",
                          fontSize: "1.7rem",
                        }}
                      >
                        ₹ {todayReport ? todayReport.revenue.toFixed() : ""}
                      </h5>
                      <p
                        className="mb-0"
                        style={{ fontSize: "15px", color: "#0d354f" }}
                      >
                        Total revenue
                      </p>
                      <div
                        style={{
                          fontSize: "10px",
                          display: "flex",
                          marginTop: "2px",
                        }}
                      >
                        <p
                          style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor:
                              percentageChanges.revenue < 0
                                ? "red"
                                : "limegreen",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {percentageChanges.revenue < 0 ? (
                            // <FaArrowDownLong style={{ color: "white" }} />
                            <img
                              src="/assests/low-price.gif"
                              style={{ width: "30px" }}
                            />
                          ) : (
                            // <FaArrowUp style={{ color: "white" }} />
                            <img
                              src="/assests/low-price.gif"
                              style={{
                                width: "30px",
                                transform: "rotate(180deg)",
                              }}
                            />
                          )}
                        </p>
                        &nbsp;{" "}
                        <p className="mb-0 yesterday">
                          {formatChange(percentageChanges.revenue)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Today Orders */}

        <div className="today-order-home">
          <h1 style={{ paddingLeft: "22px" }} className="h1">
            Today Order
          </h1>

          <Paper
            sx={{
              width: "100%",
              marginLeft: "26px",
              marginTop: "30px",
              border: "1px solid lightgrey",
            }}
            className="table-width"
          >
            <TableContainer component={Paper} className="today-order-table">
              <Table sx={{ minWidth: 600 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="tableHead">
                      <span className="head">Date</span>
                    </TableCell>
                    <TableCell className="tableHead">
                      <span className="head">Delivery Date</span>
                    </TableCell>
                    <TableCell className="tableHead">
                      <span className="head">Order id</span>
                    </TableCell>
                    <TableCell className="tableHead">
                      <span className="head">Order Type</span>
                    </TableCell>
                    <TableCell className="tableHead">
                      <span className="head">Product</span>
                    </TableCell>
                    <TableCell className="tableHead">
                      <span className="head">Quantity</span>
                    </TableCell>
                    <TableCell className="tableHead">
                      <span className="head">Delivery slot</span>
                    </TableCell>
                    <TableCell className="tableHead">
                      <span className="head">Address</span>
                    </TableCell>
                    <TableCell className="tableHead">
                      <span className="head">Payment</span>
                    </TableCell>
                    {/* <TableCell className="tableHead">
                      <span className="head">Status</span>
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => (
                      <TableRow
                        hover
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="tablebody"
                        >
                          <p className="width">
                            {" "}
                            {moment(row.createdAt).format("YYYY MM DD")}
                          </p>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className="tablebody"
                        >
                          <p className="width">
                            {" "}
                            {moment(row.deliveryDate).format("Do MMMM YYYY")}
                          </p>
                        </TableCell>
                        <TableCell className="tablebody">
                          <p className="width"> {row._id.slice(-5)}</p>
                        </TableCell>
                        <TableCell className="tablebody">
                          <p className="width"> {row.type}</p>
                        </TableCell>

                        <TableCell className="tablebody">
                          <div
                            style={{
                              width: "200px",
                              display: "flex",
                            }}
                          >
                            <div>
                              {row &&
                              row.lineItems &&
                              row.lineItems.length > 0 &&
                              row.lineItems[0].product &&
                              row.lineItems[0].product.image ? (
                                <img
                                  src={`${API_URL}/images/${row.lineItems[0].product.image}`}
                                  style={{ width: "50px", height: "50px" }}
                                  alt="meat"
                                  className="px-2"
                                />
                              ) : (
                                ""
                              )}
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <p>
                                {row.lineItems && row.lineItems.length > 0
                                  ? row.lineItems
                                      .map((val, i) =>
                                        val.product && val.product.name
                                          ? val.product.name
                                          : "Unknown product"
                                      )
                                      .join(", ")
                                  : "No products available"}
                              </p>

                              <p> &#8377; {row.paymentAmount.toFixed()}</p>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className="tablebody">
                          <p>
                            {row.lineItems && row.lineItems.length > 0
                              ? row.lineItems
                                  .map((val, i) => val.quantity)
                                  .join(", ")
                              : "No quantity available"}
                          </p>
                        </TableCell>
                        <TableCell className="tablebody">
                          <p className="width-1"> {row.deliverySlot}</p>
                        </TableCell>
                        <TableCell className="tablebody">
                          <p> {row.address}</p>
                        </TableCell>
                        <TableCell className="tablebody">
                          <div>
                            <p>{row.paymentMethod}</p>
                            {row.status === "completed" ? (
                              <div
                                style={{
                                  fontSize: "0.7rem",
                                  color: "limegreen",
                                }}
                              >
                                Paid
                              </div>
                            ) : (
                              <div style={{ fontSize: "0.7rem", color: "red" }}>
                                Pending
                              </div>
                            )}
                          </div>
                        </TableCell>
                        {/* <TableCell className='tablebody'><p className='m-0' style={{textDecoration:"underline",cursor:"pointer"}} onClick={handleLeavereqOpen}>View Note</p></TableCell> */}
                        {/* <TableCell className="tablebody">
                          {row.Status ? (
                            <>
                              <Space wrap>
                                <Select
                                  placeholder="Pending"
                                  variant="borderless"
                                  style={{
                                    width: "130px",
                                    height: "35px",
                                    color: "black",
                                  }}
                                  className="select-pending pending"
                                  onChange={onChange}
                                  options={[
                                    {
                                      value: "Pending",
                                      label: "Pending",
                                    },
                                    {
                                      value: "Out for delivery",
                                      label: "Out for delivery",
                                    },
                                    {
                                      value: "Completed",
                                      label: "Completed",
                                    },
                                  ]}
                                />
                              </Space>
                            </>
                          ) : (
                            <>
                              <Space wrap>
                                <Select
                                  placeholder="Pending"
                                  variant="borderless"
                                  style={{
                                    width: "130px",
                                    height: "35px",
                                    color: "black",
                                  }}
                                  className="select-pending pending"
                                  onChange={onChange}
                                  options={[
                                    {
                                      value: "Pending",
                                      label: "Pending",
                                    },
                                    {
                                      value: "Out for delivery",
                                      label: "Out for delivery",
                                    },
                                    {
                                      value: "Completed",
                                      label: "Completed",
                                    },
                                  ]}
                                />
                              </Space>
                            </>
                          )}
                        </TableCell> */}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              {data.length ? (
                ""
              ) : (
                <h1 className="text-center py-3">No orders for today !</h1>
              )}
            </TableContainer>
          </Paper>
        </div>

        {/* WEEKLY REPORT */}
      </div>
    </div>
  );
}
