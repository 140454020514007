import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Checkbox, Col, Row } from "antd";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Management = () => {
  const [open, setOpen] = React.useState(false);
  const [editopen, seteditopen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
  ];
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const handleeditOpen = () => {
    seteditopen(true);
  };

  const handleeditClose = () => {
    seteditopen(false);
  };

  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };

  return (
    <>
      <section className="mx-3 px-4">
        <Container>
          <div className="py-4 d-flex justify-content-between align-items-center">
            <h1 className="h1">Admin Management</h1>
            <Button varient="contained" onClick={handleOpen}>
              Create subadmin
            </Button>
          </div>
          <div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                <TableHead>
                  <TableRow>
                    <TableCell  className="tableHead">S.No</TableCell>
                    <TableCell className="tableHead">Name</TableCell>
                    <TableCell className="tableHead">Email</TableCell>
                    <TableCell className="tableHead">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row"  className="tablebody">
                        1
                      </TableCell>
                      <TableCell  className="tablebody">Pravin</TableCell>
                      <TableCell  className="tablebody">mpravin@gmail.com</TableCell>
                      <TableCell>
                        <FaEdit
                          onClick={handleeditOpen}
                          className="mx-1 fs-5"
                          style={{ color: "green", cursor: "pointer" }}
                        />
                        <MdDelete
                          className="mx-1 fs-5"
                          style={{ color: "red", cursor: "pointer" }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Container>
      </section>

      {/* create subadamin model */}

      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="d-flex justify-content-between align-items-center">
              <h3>Create Subadmin</h3>
              <IoIosCloseCircleOutline
                style={{ fontSize: "20px", cursor: "pointer" }}
                onClick={handleClose}
              />
            </div>
            <div>
              <label>Name:</label>
              <br />
              <input className="create-input p-1" />
              <br />
              <label>Email:</label>
              <br />
              <input className="create-input p-1" />
              <br />
              <label>Password:</label>
              <br />
              <input className="create-input p-1" />
              <div className="py-3 text-center">
                <Button variant="contained" className="add-btn">
                  Add
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>

      {/* Edit admin modal */}
      <div>
        <Modal
          open={editopen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="d-flex justify-content-between align-items-center">
              <h3>Edit Subadmin</h3>
              <IoIosCloseCircleOutline
                style={{ fontSize: "20px", cursor: "pointer" }}
                onClick={handleeditClose}
              />
            </div>
            <div>
              <label>Name:</label>
              <br />
              <input className="create-input p-1" />
              <br />
              <label>Email:</label>
              <br />
              <input className="create-input p-1" />
              <br />
              <label>Password:</label>
              <br />
              <input className="create-input p-1" />
              <br/>
              <div className="pt-3">
              <Checkbox.Group
                style={{
                  width: "100%",
                }}
                onChange={onChange}
              >
                <Row>
                <Col span={8}>
                    <Checkbox value="all">All</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="product">Product</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="orders">Orders</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="banners">Banners</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="business with us">Business with us</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="transaction">Transaction</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="management">Management</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="franchise">Franchise</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
              </div>
              <div className="py-3 text-center">
                <Button variant="contained" className="add-btn">
                  Update
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Management;
