import { Button } from "@mui/material";
import { Input } from "antd";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { IoMdPerson } from "react-icons/io";
import { Link } from "react-router-dom";
import "./App.css";
import Snackbar from '@mui/material/Snackbar';
import { ApiEndPoints } from "./Api/Apiendpoints";
import {loginUser} from "./Api/Apicalls"
import { message, Upload } from "antd";



const Login = () => {

  const [loginData,isLoginData]=useState({
    email:"",
    password:""
  })

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content) => {
    messageApi.open({
      type: 'success',
      content: content,
    });
  };

  const errormessage = (content) => {
    messageApi.open({
      type: 'error',
      content: content,
    });
  };

    //Login Function
    const handleLoginuser = async (e) => {
      e.preventDefault();
  
      if(loginData.email===""||loginData.password===""){
        errormessage("Need email and password")
      }
      else if(loginData.password.length<6){
        errormessage("Please check password and must be greater than 6letters")
      }
      else{
        try {
          const result = await loginUser(ApiEndPoints("login"), loginData);
          if(result.role==="admin" && result.token !== null){
              localStorage.setItem("dashtoken", result.token);
              window.dispatchEvent(new Event("storage"));
            successmessage("Login successfully done")
            window.location.reload();
          }
          else{
            errormessage("Only admins are allowed...")
          }
        } catch (error) {
          errormessage("Something went wrong")
        }
      }
  
    };

  return (
    <>
{contextHolder}
      <section className="login-bg">
        <Container>
          <div
            className="row d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
              <div className="py-3 login-box">
                <h2 className="title text-center topic">Welcome</h2>
                <p className="text-center log">
                  Enter your valid Email id and password
                </p>
                <label className="label py-2 sub-t">Email id</label>
                <br />
                <Input
                  size="large"
                  suffix={<IoMdPerson style={{ color: "grey" }} />}
                  placeholder="Enter your email id"
                  style={{ height: "45px", width: "100%" }}
                  onChange={(e)=>isLoginData({...loginData,email:e.target.value})}
                />
                <br />
                <label className="label py-2 sub-t">Password</label>
                <br />
                <Input.Password
                  placeholder="Enter your password"
                  style={{ height: "45px", width: "100%" }}
                  onChange={(e)=>isLoginData({...loginData,password:e.target.value})}
                />
                <br />
                <div className="text-end pt-2 pb-4">
                  <Link to="/forget-password" className="forgetpass">
                    Forget password
                  </Link>
                </div>
                <div className="text-center log-btn pt-3">
                  <button className="but" onClick={handleLoginuser}>Login</button>
                 {/* <p className="sub-t-1">
  Don't have an account?
  <Link to="/sign-up">Signup</Link>
</p> */}

                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Login;
