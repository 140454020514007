import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Input, message } from "antd";
import axios from "axios";
import { API_URL } from "./Api/Config";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Franchies = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
const [newFransData,setnewFransData]=useState({
    branchName:"",
    email:"",
    password:"",
    address:""
})
const [getAllFransData,setgetallFransData]=useState([])
const [ogData,setOgdata]=useState([])
const [filter,setfilter]=useState("")
  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };

  const errormessage = (content) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };

  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };

  const handlecreateFrans=async()=>{
    if(newFransData.address===""||newFransData.branchName===""||newFransData.email===""||newFransData.password===""){
        errormessage("Please fill all fields")
    }
    else{
        try{
            const res=await axios.post(`${API_URL}/api/franchise/auth`,newFransData)
            successmessage("Account created")
            setOpen(false)
        }
        catch(error){
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
              ) {
                errormessage(error.response.data.message);
              } else {
                errormessage("An unexpected error occurred. Please try again.");
              }
        }
    }
  }


  const getAllFrans=async()=>{
    try{
        const res=await axios.get(`${API_URL}/api/franchise`)
        setgetallFransData(res.data.franchises|| [])
        setOgdata(res.data.franchises|| [])
    }
    catch(error){
        if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errormessage(error.response.data.message);
          } else {
            errormessage("An unexpected error occurred. Please try again.");
          }
    }
  }

  const handleFilter=()=>{
    if(filter){
        const datafilter=ogData.filter((val)=>filter===val._id.slice(-5))
        setgetallFransData(datafilter|| [])
    }
    else{
        errormessage("Select id")
    }
  }

  const handleReset=()=>{
    setgetallFransData(ogData|| [])
    setfilter("")
  }

  useEffect(()=>{
    getAllFrans()
  },[])

  return (
    <>
    {contextHolder}
      <section className="mx-3 px-4">
        <Container>
          <div className="py-4 d-flex justify-content-between align-items-center">
            <div className="col-lg-6">
            <Input placeholder="Search by Id" value={filter} className="me-2" style={{width:"200px"}} onChange={(e)=>setfilter(e.target.value)}/>
            <Button variant="text" onClick={handleFilter} className="filter-btn">
              Filter
            </Button>
            <Button variant="text" onClick={handleReset} className="filter-btn">
              Reset
            </Button>
            </div>
            <div className="col-lg-6 text-end">
            <Button varient="contained" onClick={handleOpen} className="filter-btn">
            Create New Franchise
            </Button>
            </div>
          </div>
          <div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                <TableHead>
                  <TableRow>
                    <TableCell  className="tableHead">Branch Name</TableCell>
                    <TableCell  className="tableHead">Branch ID</TableCell>
                    <TableCell className="tableHead">Address</TableCell>
                    <TableCell className="tableHead">Email</TableCell>
                    <TableCell className="tableHead">Orders Done</TableCell>
                    <TableCell className="tableHead">Cash In Hand</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getAllFransData.map((row,i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row"  className="tablebody">
                        {row.branchName}
                      </TableCell>
                      <TableCell  className="tablebody">{row._id.slice(-5)}</TableCell>
                      <TableCell  className="tablebody">{row.address}</TableCell>
                      <TableCell  className="tablebody">{row.email}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Container>
      </section>

      {/* create subadamin model */}

      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="d-flex justify-content-between align-items-center">
              <h3>Create New Franchise</h3>
              <IoIosCloseCircleOutline
                style={{ fontSize: "20px", cursor: "pointer" }}
                onClick={handleClose}
              />
            </div>
            <div>
              <label>Branch Name: <span style={{color:"red"}}>*</span></label>
              <br />
              <input className="create-input p-1" onChange={(e)=>setnewFransData({...newFransData,branchName:e.target.value})}/>
              <br />
              <label>Address: <span style={{color:"red"}}>*</span></label>
              <br />
              <input className="create-input p-1"  onChange={(e)=>setnewFransData({...newFransData,address:e.target.value})}/>
              <br />
              <label>Email: <span style={{color:"red"}}>*</span></label>
              <br />
              <input className="create-input p-1"  onChange={(e)=>setnewFransData({...newFransData,email:e.target.value})}/>
              <br />
              <label>Password: <span style={{color:"red"}}>*</span></label>
              <br />
              <input className="create-input p-1"  onChange={(e)=>setnewFransData({...newFransData,password:e.target.value})}/>
              <div className="py-3 text-center">
                <Button variant="contained" className="add-btn" onClick={handlecreateFrans}>
                  Add
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>

    </>
  );
};

export default Franchies;
