import React, { useState } from 'react';
import { Upload, Button, DatePicker, Image, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';

const { RangePicker } = DatePicker;
const { Dragger } = Upload;

const StaticBanner = () => {
  const [fileList, setFileList] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const props = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      // Generate a preview
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setPreviewImage(reader.result);
      reader.onerror = (error) => console.error(error);

      setFileList([file]); // Store the selected file in fileList state
      return false; // Prevent upload
    },
    onRemove: () => {
      setFileList([]); // Clear the fileList when a file is removed
      setPreviewImage(""); // Clear the preview image
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  // Handle date range changes
  const onRangeChange = (dates) => {
    setSelectedTimes(dates); // Dates should be an array with start and end dates
  };

  // Function to handle form submission
  const handleSubmit = async () => {
    if (!fileList.length || !selectedTimes || selectedTimes.length !== 2) {
      message.error('Please select an image and a time range.');
      return;
    }

    const formData = new FormData();
    formData.append('background', fileList[0]); 
    formData.append('image', fileList[0]);
    formData.append('startDate', selectedTimes[0].toISOString());
    formData.append('endDate', selectedTimes[1].toISOString());
    formData.append('isTemporary', true); 
    formData.append('position', 1);
    formData.append('heading', "static");
    formData.append('content', "static");

    for (const [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }

    setLoading(true);

    try {
      const response = await axios.post('https://api.meatmarket.live/api/heroPage/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('Banner data successfully submitted!');
      window.location.reload()
      setFileList([]);
      setPreviewImage("");
      setSelectedTimes(null); 
    } catch (error) {
      message.error('Failed to submit banner data.Image must be under 5mb.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="toggle">
      <label className="label py-2">Banner Image</label>
    <div style={{width:"40%"}}>
      <Dragger {...props} fileList={fileList}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined style={{color:"#0d354f"}}/>
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
      </Dragger>
      </div>

      <div className="py-2">
        {previewImage && (
          <div>
            <label className="label py-2">Selected Image Preview</label>
            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
              <Image width={100} src={previewImage} alt="Selected Image" />
            </div>
          </div>
        )}
      </div>

      <br />
      <label className="label py-2">Timing set</label>
      <br />
      <RangePicker
        onChange={onRangeChange}
        // value={selectedTimes ? [moment(selectedTimes[0]), moment(selectedTimes[1])] : null}
        format="YYYY-MM-DD" // Set format for date only
      />
      <div className="py-3">
        <Button type="primary" className='add-btn' onClick={handleSubmit} loading={loading}>
          ADD
        </Button>
      </div>
    </div>
  );
};

export default StaticBanner;
