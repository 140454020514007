import React, { useEffect, useState } from "react";
import "./App.css";
import { Select, Space, DatePicker, Input, Radio } from "antd";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TbShoppingCartUp, TbShoppingCartMinus } from "react-icons/tb";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { allOrders, getReportMonth } from "./Api/Apicalls";
import { ApiEndPoints } from "./Api/Apiendpoints";
import moment from "moment";
import { TablePagination } from "@mui/material";
import axios from "axios";

export default function Port() {
  const { RangePicker } = DatePicker;

  const onChangeM = (value) => {
    console.log("selected", value);
  };

  //States
  const [orderDataCount, setOrderDatacount] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [ogData, setOgdata] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [filterDate, setFilterDate] = useState(null);
  const [filterMonth, setFilterMonth] = useState(null);
  const [filterOrderId, setFilterOrderId] = useState("");
  const [filterPick, setFilterPick] = useState("month");
  const [filterPickData, setFilterpickData] = useState(null);
  const [filterPickR, setFilterPickR] = useState("month");
  const [filterPickDataR, setFilterpickDataR] = useState(null);

  const handleDateChange = (date, dateString) => {
    setFilterDate(dateString);
  };

  const handleMonthChange = (month, dateString) => {
    setFilterMonth(dateString);
  };

  const handleOrderIdChange = (e) => {
    setFilterOrderId(e.target.value);
  };

  const filterOrders = () => {
    let filteredOrders = [];

    if (filterDate) {
      filteredOrders = ogData.filter(
        (order) =>
          moment(order.deliveryDate).format("YYYY-MM-DD") === filterDate
      );
    }
    if (filterMonth) {
      filteredOrders = ogData.filter(
        (order) => moment(order.deliveryDate).format("YYYY-MM") === filterMonth
      );
    }

    if (filterOrderId) {
      filteredOrders = ogData.filter(
        (order) => order.user._id.slice(-5) === filterOrderId.trim()
      );
    }

    setOrderData(filteredOrders);
  };

  //Getreport
  const getReports = async () => {
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    try {
      const response = await getReportMonth(
        ApiEndPoints("report"),
        year,
        month
      );

      setOrderDatacount(response.report);
      await getAllorders();
    } catch (err) {
      console.error(err);
    }
  };

  //Get all orders
  const getAllorders = async () => {
    try {
      const response = await allOrders(ApiEndPoints("getAllorders"));
      const sortedOrders = response.orders.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
  
      // Aggregate data by user
      const userStats = {};
      sortedOrders.forEach((order) => {
        const userId = order.user._id;
        const paymentAmount = order.paymentAmount || 0;
  
        if (userStats[userId]) {
          userStats[userId].orderCount += 1;
          userStats[userId].totalAmount += paymentAmount;
        } else {
          userStats[userId] = {
            orderCount: 1,
            totalAmount: paymentAmount,
            user: order.user, // Store user details
          };
        }
      });

      console.log(userStats);
      
  
      // Convert the aggregated data to an array of unique users
      const uniqueUsers = Object.values(userStats);
  
      // Update state with the unique user data
      setOgdata(uniqueUsers);
      setOrderData(uniqueUsers);
    } catch (err) {
      console.error(err);
    }
  };
  

  useEffect(() => {
    getReports();
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (value) => {
    setFilterPick(value);
  };

  const handleChangeR = (value) => {
    setFilterPickR(value);
  };

  const handlefilterpickChange = (date, dateString) => {
    setFilterpickData(dateString);
  };

  const handlefilterpickChangeR = (date, dateString) => {
    setFilterpickDataR(dateString);
  };
  const [leftData, setLeftData] = useState(null);
  const [rightData, setrightData] = useState(null);
  const fetchData = async () => {
    let queryParams = "";

    if (filterPick === "year" && filterPickData) {
      queryParams = `year=${new Date(filterPickData).getFullYear()}`;
    } else if (filterPick === "month" && filterPickData) {
      const [year, month] = filterPickData.split("-");
      queryParams = `month=${month}&year=${year}`;
    } else if (filterPick === "day" && filterPickData) {
      const date = new Date(filterPickData);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      queryParams = `day=${day}&month=${month}&year=${year}`;
    }

    const url = `https://api.meatmarket.live/api/report?${queryParams}`;

    try {
      const response = await axios.get(url);
      setLeftData(response.data.report);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataR = async () => {
    let queryParams = "";

    if (filterPickR === "year" && filterPickDataR) {
      queryParams = `year=${new Date(filterPickDataR).getFullYear()}`;
    } else if (filterPickR === "month" && filterPickDataR) {
      const [year, month] = filterPickDataR.split("-");
      queryParams = `month=${month}&year=${year}`;
    } else if (filterPickR === "day" && filterPickDataR) {
      const date = new Date(filterPickDataR);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      queryParams = `day=${day}&month=${month}&year=${year}`;
    }

    const url = `https://api.meatmarket.live/api/report?${queryParams}`;

    try {
      const response = await axios.get(url);
      setrightData(response.data.report);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (filterPickData ) {
      fetchData();
      fetchDataR();
    } 
  }, [filterPickData, filterPickDataR,filterPick]);

  const [percentages, setPercentages] = useState({
    ordersPercentage: 0,
    revenuePercentage: 0,
    bulkOrdersPercentage: 0,
  });

  const calculatePercentageChange = (oldValue, newValue) => {
    if (oldValue === 0 && newValue === 0) return 0;
    if (oldValue === 0) return 100; // 100% increase if the old value was 0 and new value isn't
    return ((newValue - oldValue) / oldValue) * 100;
  };

  return (
    <div className="mx-3">
      <div style={{ padding: "30px 30px", color: "#0d354f" }}>
        <div
          className="d-flex down"
          style={{ justifyContent: "space-between", marginRight: "40px" }}
        >
          <div>
            <h1 className="h1">Report</h1>
          </div>
        </div>

        {/* ROW AND COLUMN */}

        <div>
          <Row className="report-row">
            <Col xs={2} md={6} lg={3} className="dd">
              <div className="report-col">
                <Row>
                  <Col xs={2} md={7} lg={9}>
                    <div>
                      <p className="detail-txt">This month order</p>
                      <h3>{orderDataCount && orderDataCount.ordersCount}</h3>
                    </div>
                  </Col>
                  <Col
                    xs={2}
                    md={5}
                    lg={3}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TbShoppingCartUp className="port-font" />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={2} md={6} lg={3} className="dd">
              <div className="report-col">
                <Row>
                  <Col xs={2} md={7} lg={9}>
                    <div>
                      <p className="detail-txt">This month Bulk order</p>
                      <h3>
                        {orderDataCount && orderDataCount.bulkOrdersCount}
                      </h3>
                    </div>
                  </Col>
                  <Col
                    xs={2}
                    md={5}
                    lg={3}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TbShoppingCartMinus className="port-font" />
                  </Col>
                </Row>
              </div>
            </Col>

            <Col xs={2} md={6} lg={3} className="dd">
              <div className="report-col">
                <Row>
                  <Col xs={2} md={7} lg={9}>
                    <div>
                      <p className="detail-txt">This month Cancelled</p>
                      <h3>
                        {orderDataCount && orderDataCount.cancelledOrdersCount}
                      </h3>
                    </div>
                  </Col>
                  <Col
                    xs={2}
                    md={5}
                    lg={3}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TbShoppingCartMinus className="port-font" />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={2} md={6} lg={3} className="dd">
              <div className="report-col">
                <Row>
                  <Col xs={2} md={7} lg={9}>
                    <div>
                      <p className="detail-txt">This month Revenue</p>
                      <h3>
                        {" "}
                        &#8377;{orderDataCount && orderDataCount.revenue}
                      </h3>
                    </div>
                  </Col>
                  <Col
                    xs={2}
                    md={5}
                    lg={3}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TbShoppingCartMinus className="port-font" />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>

        {/* ROW */}

        <div className="d-flex product-search-combine">
          {/* <DatePicker
            style={{ width: "100%", padding: "5px" }}
            onChange={handleDateChange}
            className="product-search"
          />

          <DatePicker
            style={{ width: "100%", padding: "5px" }}
            onChange={handleMonthChange}
            className="product-search"
            picker="month"
          /> */}

          <Input
            placeholder="Search by Customer id"
            style={{ width: "100%", padding: "5px" }}
            className="select-pending product-search"
            onChange={handleOrderIdChange}
          />

          <div>
            <button
              style={{
                border: "1px solid #0d354f",
                backgroundColor: "#0d354f",
                color: "white",
                borderRadius: "5px",
                padding: "5px 50px",
              }}
              onClick={filterOrders}
            >
              Filter
            </button>
          </div>
        </div>

        {/* TABLE */}
        <div>
          <Paper
            sx={{
              width: "95.5%",
              marginTop: "30px",
              border: "1px solid lightgrey",
            }}
            className="table-width"
          >
            <TableContainer component={Paper} className="today-order-table">
              <Table sx={{ minWidth: 600 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="tableHead">
                      <span className="head">Customer name</span>
                    </TableCell>
                    <TableCell className="tableHead">
                      <span className="head">Customer id</span>
                    </TableCell>
                    <TableCell className="tableHead">
                      <span className="head">Total orders</span>
                    </TableCell>

                    <TableCell className="tableHead">
                      <span className="head">Total Amount</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => (
                      <TableRow
                        hover
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell className="tablebody">
                          {row.user.name}
                        </TableCell>

                        <TableCell className="tablebody">
                          {row.user._id.slice(-5)}
                        </TableCell>
                        <TableCell className="tablebody">
                          {row.orderCount}
                        </TableCell>
                        <TableCell className="tablebody">
                          {row.totalAmount.toFixed()}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={orderData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className="pt-4 px-4"
              />
            </TableContainer>
          </Paper>
        </div>

        {/* SALES REPORT */}

        <section className="py-4 sales">
          <div>
            <h2 className="pb-3">Sales Report Comparision</h2>
          </div>
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12">
              <div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="pb-3">
                      <Select
                        className="me-1"
                        defaultValue="month"
                        style={{
                          width: 120,
                        }}
                        onChange={handleChange}
                        options={[
                          {
                            value: "month",
                            label: "Month",
                          },
                          {
                            value: "day",
                            label: "Day",
                          },
                          // {
                          //   value: 'year',
                          //   label: 'Year',
                          // },
                        ]}
                      />
                      <DatePicker
                        style={{ width: "100%", padding: "5px" }}
                        onChange={handlefilterpickChange}
                        className="product-search"
                        picker={filterPick}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="py-3">
                      <div className="table-box p-3">
                        <table style={{ width: "100%" }}>
                          <tr>
                            <td className="total">Total Orders</td>
                            <td className="totalcon">
                              {leftData && leftData.ordersCount}
                            </td>
                          </tr>
                          <tr>
                            <td className="total py-2">Total Revenue </td>
                            <td className="totalcon">
                            ₹ {leftData && leftData.revenue}
                            </td>
                          </tr>
                          <tr>
                            <td className="total">Total Bulk Orders</td>
                            <td className="totalcon">
                              {leftData && leftData.bulkOrdersCount}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-0 d-flex justify-content-center align-items-center">
              <div>
                <h1 className="pt-5">Vs</h1>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12">
              <div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="pb-3">
                      <Select
                        className="me-1"
                        defaultValue="month"
                        style={{
                          width: 120,
                        }}
                        onChange={handleChangeR}
                        options={[
                          {
                            value: "month",
                            label: "Month",
                          },
                          {
                            value: "day",
                            label: "Day",
                          },
                          // {
                          //   value: 'year',
                          //   label: 'Year',
                          // },
                        ]}
                      />
                      <DatePicker
                        style={{ width: "100%", padding: "5px" }}
                        onChange={handlefilterpickChangeR}
                        className="product-search"
                        picker={filterPickR}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="py-3">
                      <div className="table-box p-3">
                        <table style={{ width: "100%" }}>
                          <tr>
                            <td className="total">Total Orders</td>
                            <td className="totalcon">
                              {rightData && rightData.ordersCount}
                            </td>
                          </tr>
                          <tr>
                            <td className="total py-2">Total Revenue </td>
                            <td className="totalcon">
                              ₹ {rightData && rightData.revenue}
                            </td>
                          </tr>
                          <tr>
                            <td className="total">Total Bulk Orders</td>
                            <td className="totalcon">
                              {rightData && rightData.bulkOrdersCount}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div>
            <h2 className="title py-3">Result</h2>
          </div> */}
          {/* <div className="table-box px-3 py-4">
            <div className="row">
              <div className="col-lg-5 col-md-5 col-sm-12">
                <div>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td className="total">Total Orders</td>
                      <td className="totalcon">
                        {leftData && leftData.ordersCount}
                      </td>
                      <td>
                        <span
                          className={`percent-bg ${
                            comparisonResults.ordersPercentageChange > 0
                              ? "percent-bg"
                              : "percent-bgdrop"
                          }`}
                        >
                          {Math.abs(
                            comparisonResults.ordersPercentageChange.toFixed(2)
                          )}
                          %
                          {comparisonResults.ordersPercentageChange > 0 ? (
                            <BiUpArrowAlt style={{ fontSize: "20px" }} />
                          ) : (
                            <BiDownArrowAlt style={{ fontSize: "20px" }} />
                          )}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="total py-2">Total Revenue </td>
                      <td className="totalcon">$ {leftData.revenue}</td>
                      <td>
                        <span
                          className={`percent-bg ${
                            comparisonResults.revenuePercentageChange > 0
                              ? "percent-bg"
                              : "percent-bgdrop"
                          }`}
                        >
                          {Math.abs(
                            comparisonResults.revenuePercentageChange.toFixed(2)
                          )}
                          %
                          {comparisonResults.revenuePercentageChange > 0 ? (
                            <BiUpArrowAlt style={{ fontSize: "20px" }} />
                          ) : (
                            <BiDownArrowAlt style={{ fontSize: "20px" }} />
                          )}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="total">Total Bulk Orders</td>
                      <td className="totalcon">{leftData.bulkOrdersCount}</td>
                      <td>
                        <span
                          className={`percent-bg ${
                            comparisonResults.bulkOrdersPercentageChange > 0
                              ? "percent-bg"
                              : "percent-bgdrop"
                          }`}
                        >
                          {Math.abs(
                            comparisonResults.bulkOrdersPercentageChange.toFixed(
                              2
                            )
                          )}
                          %
                          {comparisonResults.bulkOrdersPercentageChange > 0 ? (
                            <BiUpArrowAlt style={{ fontSize: "20px" }} />
                          ) : (
                            <BiDownArrowAlt style={{ fontSize: "20px" }} />
                          )}
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-0"></div>
              <div className="col-lg-5 col-md-5 col-sm-12">
                <div>
                  {" "}
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td className="total">Total Orders</td>
                      <td className="totalcon">150</td>
                      <td>
                        <span className="percent-bgdrop">
                          5%
                          <BiDownArrowAlt style={{ fontSize: "20px" }} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="total py-2">Total Revenue </td>
                      <td className="totalcon">$ 100.00</td>
                      <td>
                        <span className="percent-bgdrop">
                          5%
                          <BiDownArrowAlt style={{ fontSize: "20px" }} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="total">Total Bulk Orders</td>
                      <td className="totalcon">65</td>
                      <td>
                        <span className="percent-bgdrop">
                          5%
                          <BiDownArrowAlt style={{ fontSize: "20px" }} />
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div> */}
        </section>
      </div>
    </div>
  );
}
