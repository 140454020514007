import React, { useEffect, useState } from "react";
import "./App.css";
import { Link } from "react-router-dom";
import { IoNotifications } from "react-icons/io5";
import { FaSortDown } from "react-icons/fa6";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FaLeaf, FaStar, FaLessThan } from "react-icons/fa";
import { IoSpeedometer } from "react-icons/io5";
import { RiDiscountPercentFill } from "react-icons/ri";
import { AiFillEdit } from "react-icons/ai";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { MdClear } from "react-icons/md";
import { Switch } from "antd";
import { Select, Input, Upload, Flex, message, Space, Progress } from "antd";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import SelectM from "@mui/material/Select";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ApiEndPoints } from "./Api/Apiendpoints";
import { allProducts, updateProduct, deleteProduct } from "./Api/Apicalls";
import Pagination from "@mui/material/Pagination";
import { API_URL } from "./Api/Config";
import { MdDelete } from "react-icons/md";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function Product() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setDiscount("");
  };

  const [performance, setPerformance] = React.useState(false);
  const handlemOpen = () => setPerformance(true);
  const handlemClose = () => setPerformance(false);

  const [age, setAge] = React.useState("Thismonth");

  const handleChangeM = (event) => {
    setAge(event.target.value);
  };

  const [per, setPercen] = React.useState("5%");

  const handleChangeS = (event) => {
    setPercen(event.target.value);
  };

  const [name, setName] = React.useState("24 hrs");

  const handleChangeG = (event) => {
    setName(event.target.value);
  };
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [productData, setProductData] = useState([]);
  const [filterData, setfilterData] = useState({
    byProductName: "",
    byProductId: null,
    byProductCategory: "",
  });
  const [productId, setProductId] = useState([]);
  const [discount, setDiscount] = useState(null);
  const [id, setId] = useState("");
  const [ogData, setOGData] = useState([]);
  const [prodName, setProdName] = useState([]);

  const getProducts = async () => {
    try {
      const getProduct = await allProducts(
        ApiEndPoints("getAllproduct"),
        currentPage
      );
      setProductData(getProduct.products);
      setOGData(getProduct.products);
      setTotalPages(getProduct.totalPages);
      const ids = getProduct.products.map((product) => product.productId);
      const prodName = getProduct.products.map((product) => product.name);
      setProdName(prodName);
      setProductId(ids);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilter = async () => {
    const { byProductId, byProductName, byProductCategory } = filterData;
    const filtered = ogData.filter((product) => {
      return (
        (!byProductId || product.productId.slice(-5) === byProductId) &&
        (!byProductName ||
          product.name.toLowerCase().includes(byProductName.toLowerCase())) &&
        (!byProductCategory || product.category === byProductCategory)
      );
    });
    setProductData(filtered);
  };

  const handleUpdateproduct = async () => {
    const formData = new FormData();
    formData.append("discountInPercent", discount);
    try {
      const response = await updateProduct(
        ApiEndPoints("updateProduct"),
        id,
        formData
      );
      setDiscount("");
      await getProducts()
      handleClose();
    } catch (err) {
      console.error(err);
    }
  };

  // Handle page change

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  const [discountDetails, setdiscountDetails] = useState({
    name: "",
    price: "",
    amount: "",
    grams: "",
    img: "",
  });

  const handlegetId = (id, product, price, minWeightInGrams, img) => {
    setdiscountDetails({
      name: product,
      price: price,
      amount: price,
      grams: minWeightInGrams,
      img: img,
    });
    setId(id);
    handleOpen();
  };

  // Delete Product

  const handleDeleteProduct = async (id) => {
    try {
      const response = await deleteProduct(ApiEndPoints("deleteProduct"), id);
      await getProducts();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getProducts();
  }, [currentPage]);

  return (
    <div className="product mx-3 ">
      <div style={{ padding: "20px 30px 30px", color: "#0d354f" }}>
        <div className="d-flex" style={{ justifyContent: "space-between" }}>
          <div>
            <h1 className="h1">Products</h1>
          </div>

          <div>
            <Link to="/addnew" activeStyle>
              <button className="new">Add New</button>
            </Link>
          </div>
        </div>

        <div className="d-flex product-search-combine">
          <Space wrap>
            <Select
              placeholder="Select by Product Name"
              className="select-pending product-search-1"
              onChange={(value) =>
                setfilterData({ ...filterData, byProductName: value })
              }
              options={prodName.map((id) => ({
                value: id,
                label: id,
              }))}
            />
          </Space>

          <Space wrap>
            <Select
              placeholder="Select by Product ID"
              className="select-pending product-search-1"
              onChange={(value) =>
                setfilterData({ ...filterData, byProductId: value })
              }
              options={productId.map((id) => ({
                value: id.slice(-5),
                label: `${id.slice(-5)}`,
              }))}
            />
          </Space>

          <Space wrap>
            <Select
              defaultValue="Search by categories"
              className="select-pending  product-search-1"
              onChange={(value) =>
                setfilterData({
                  ...filterData,
                  byProductCategory: value,
                })
              }
              options={[
                {
                  value: "chicken",
                  label: "Chicken",
                },
                {
                  value: "mutton",
                  label: "Mutton",
                },
              ]}
            />
          </Space>

          <div>
            <button
              style={{
                border: "1px solid #0d354f",
                backgroundColor: "#0d354f",
                color: "white",
                borderRadius: "5px",
                padding: "5px 50px",
              }}
              className="but"
              onClick={handleFilter}
            >
              Filter
            </button>
          </div>
        </div>
      </div>
      <Paper
        sx={{
          width: "95.5%",
          marginLeft: "26px",
          marginTop: "5px",
          border: "1px solid lightgrey",
        }}
        className="table-width"
      >
        <TableContainer component={Paper} className="today-order-table">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="tableHead">
                  <span className="head">Product id</span>
                </TableCell>
                <TableCell className="tableHead">
                  <span className="head">Product</span>
                </TableCell>
                <TableCell className="tableHead">
                  <span className="head">categories</span>
                </TableCell>
                <TableCell className="tableHead">
                  <span className="head">Price/Weight</span>
                </TableCell>
                {/* <TableCell className="tableHead">
                  <span className="head">Performance</span>
                </TableCell> */}
                <TableCell className="tableHead">
                  <span className="head">Ratings</span>
                </TableCell>
                <TableCell className="tableHead">
                  <span className="head">Discount Price</span>
                </TableCell>
                <TableCell className="tableHead">
                  <span className="head">Discount</span>{" "}
                </TableCell>
                <TableCell className="tableHead">
                  <span className="head">Action</span>{" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productData.map((row, i) => (
                <TableRow
                  hover
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" className="tablebody">
                    <p> {row.productId.slice(-5)}</p>
                  </TableCell>
                  <TableCell className="tablebody">
                    <div
                      className="d-flex"
                      style={{ justifyContent: "start", gap: "20px" }}
                    >
                      <div>
                        <img
                          src={`${API_URL}/images/${row.image}`}
                          style={{ width: "50px", height: "50px" }}
                          alt="meat"
                        />{" "}
                        {row.name}
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p> {row.Product}</p>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell className="tablebody">
                    <p> {row.category}</p>
                  </TableCell>
                  <TableCell className="tablebody">
                    <p>
                      &#8377; {row.price} /{row.minWeightInGrams} g
                    </p>
                  </TableCell>

                  {/* <TableCell className="tablebody">
                    <div
                      style={{
                        cursor: "pointer",
                        width: "50px",
                        padding: "5px",
                        border: "1px solid lightgrey",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={handlemOpen}
                    >
                      <div>
                        <IoSpeedometer style={{ fontSize: "1.4rem" }} />
                      </div>
                    </div>
                  </TableCell> */}
                  <TableCell className="tablebody">
                    <div className="d-flex" style={{ gap: "5px" }}>
                      <div>
                        <FaStar
                          style={{ color: "crimson", fontSize: "1rem" }}
                        />
                      </div>
                      <p>{row.rating}</p>
                    </div>
                  </TableCell>
                  <TableCell className="tablebody">
                    <p>
                      {row.price -
                        ((row.price * row.discountInPercent) / 100).toFixed()}
                      /{row.minWeightInGrams} g
                    </p>
                  </TableCell>
                  <TableCell className="tablebody">
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handlegetId(
                          row._id,
                          row.Product,
                          row.price,
                          row.minWeightInGrams,
                          row.image
                        )
                      }
                    >
                      <p>{row.discountInPercent}</p>
                      <p>
                        <RiDiscountPercentFill style={{ fontSize: "1.4rem" }} />
                      </p>
                    </div>
                  </TableCell>
                  <TableCell className="tablebody">
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "50px",
                        height: "50px",
                      }}
                    >
                      <Link to={`/edit-product/${row._id}`}>
                        <AiFillEdit
                          style={{ fontSize: "1.4rem", color: "#0d354f" }}
                        />
                      </Link>
                      <MdDelete
                        onClick={() => handleDeleteProduct(row._id)}
                        style={{ fontSize: "1.4rem", color: "#0d354f" }}
                      />

                      {/* {row.Action} */}
                    </div>
                  </TableCell>
                  <TableCell className="tablebody">
                    {row.Status ? <></> : <></>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box
        sx={{ display: "flex", justifyContent: "center", mt: 2 }}
        className="pb-3"
      >
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handleChangePage}
          variant="outlined"
          color="primary"
        />
      </Box>
      {/* Modal */}
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="offer">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div className="dicount-combine">
                <div>
                  <h6>Discount</h6>
                </div>

                <div>
                  <h6>
                    <MdClear
                      onClick={handleClose}
                      style={{ cursor: "pointer" }}
                    />
                  </h6>
                </div>
              </div>
            </Typography>
            <div className="d-flex discount-meat">
              <div className="d-flex discount-meatcombine">
                <div>
                  <img
                    style={{ width: "50px", height: "50px" }}
                    src={`${API_URL}/images/${discountDetails.img}`}
                    alt="meat"
                  />
                </div>
                <div>
                  <p className="discount-meat-text">{discountDetails.name}</p>
                  <p className="discount-meat-text">
                    &#8377; {discountDetails.amount}/{discountDetails.grams}
                  </p>
                </div>
              </div>
              {/* <div>
                <Switch defaultChecked size="small" />
              </div> */}
            </div>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div className="d-flex visible">
                <div>
                  <p></p>
                  <p>
                    Price to be displayed :{" "}
                    {discountDetails.amount -
                      ((discountDetails.amount * discount) / 100).toFixed()}
                  </p>
                  {}
                  <Box sx={{ minWidth: 120, marginTop: "10px" }}>
                    <FormControl fullWidth>
                      <label>
                        Discount (%) <span>(Ex:10)</span>
                      </label>
                      <Input
                        placeholder="Enter dicount percentage"
                        className="select-pending product-search"
                        onChange={(e) => setDiscount(Number(e.target.value))}
                      />
                    </FormControl>
                  </Box>
                </div>
              </div>
            </Typography>
            <Typography>
              <div className="d-flex di-save">
                <button onClick={handleUpdateproduct}>Save</button>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>

      {/*Performance Modal */}
      <div>
        <Modal
          open={performance}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="offer">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div className="dicount-combine">
                <div>
                  <h6>Performance</h6>
                </div>

                <div>
                  <h6>
                    <MdClear
                      onClick={handlemClose}
                      style={{ cursor: "pointer" }}
                    />
                  </h6>
                </div>
              </div>
            </Typography>
            <div className="d-flex discount-meat">
              <div className="d-flex discount-meatcombine">
                <div>
                  <img
                    style={{ width: "50px", height: "50px" }}
                    src="/assests/meatimg.png"
                    alt="meat"
                  />
                </div>
                <div>
                  <p className="discount-meat-text">Chicken Keema</p>
                </div>
              </div>
              <div>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <SelectM
                      value={age}
                      onChange={handleChangeM}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      className="discount-percentage"
                    >
                      <MenuItem value={"Thismonth"}>This month</MenuItem>
                      <MenuItem value={"Lastmonth"}>Last month</MenuItem>
                      <MenuItem value={"Last6month"}>Last 6 month</MenuItem>
                      <MenuItem value={"Lastyear"}>Last year</MenuItem>
                    </SelectM>
                  </FormControl>
                </Box>
              </div>
            </div>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Row style={{ marginTop: "30px" }}>
                <Col xs>
                  <div className="perform-div">
                    <p>Quantity sold</p>
                    <p>Total orders</p>
                    <p>Cancelled orders</p>
                    <p>Total revenue</p>
                  </div>
                </Col>
                <Col xs={{ order: 12 }}>
                  <div className="perform-div">
                    <p>3500(Kg)</p>
                    <p>550</p>
                    <p>2</p>
                    <p> &#8377; 200</p>
                  </div>
                </Col>
                <Col xs={{ order: 1 }}>
                  <div>
                    <Flex vertical gap="small" style={{}}>
                      <Progress
                        percent={5}
                        percentPosition={{
                          align: "start",
                          type: "outer",
                        }}
                        strokeColor="red"
                        size="small"
                        format={(percent) => `${percent} % of all`}
                      />

                      <Progress
                        percent={5}
                        percentPosition={{
                          align: "start",
                          type: "outer",
                        }}
                        strokeColor="red"
                        size="small"
                        format={(percent) => `${percent} % of all`}
                      />
                      <Progress
                        percent={45}
                        percentPosition={{
                          align: "start",
                          type: "outer",
                        }}
                        strokeColor="red"
                        size="small"
                        format={(percent) => `${percent} % of all`}
                      />
                      <Progress
                        percent={80}
                        percentPosition={{
                          align: "start",
                          type: "outer",
                        }}
                        strokeColor="limegreen"
                        size="small"
                        format={(percent) => `${percent} % of all`}
                      />
                    </Flex>
                  </div>
                </Col>
              </Row>
            </Typography>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
