import React, { useState, useEffect } from "react";
import "./App.css";
import { IoNotifications } from "react-icons/io5";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FaDownload } from "react-icons/fa6";
import Pagination from "@mui/material/Pagination";
import { allOrders, getAllrequest } from "./Api/Apicalls";
import { ApiEndPoints } from "./Api/Apiendpoints";
import { API_URL } from "./Api/Config";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Business() {
  const [value, setValue] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  console.log(data, "data");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  const getAllOrders = async () => {
    try {
      const response = await getAllrequest(
        ApiEndPoints("franchise"),
        currentPage
      );
      setData(response.requests);
      setTotalPages(response.totalPages);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDownload = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "image.jpg"; 
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  useEffect(() => {
    getAllOrders();
  }, [currentPage]);

  return (
    <div className="mx-3 px-4">
      <div style={{ padding: "20px 30px", color: "#0d354f" }}>
        <div className="d-flex" style={{ justifyContent: "space-between" }}>
          <div>
            <h1 className="h1">Business with us</h1>
          </div>
        </div>

        <Box sx={{ width: "100%", marginTop: "20px" }}>
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Become a franchise" {...a11yProps(0)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Paper
              className="table-width"
              sx={{
                width: "95.5%",
                overflow: "hidden",
                border: "1px solid lightgrey",
                marginRight: "20px",
              }}
            >
              <TableContainer component={Paper} className="today-order-table">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="tableHead">
                        <span className="head">Name</span>
                      </TableCell>
                      <TableCell className="tableHead">
                        <span className="head">Phone no</span>
                      </TableCell>
                      <TableCell className="tableHead">
                        <span className="head">Email id</span>
                      </TableCell>
                      <TableCell className="tableHead">
                        <span className="head">Address</span>
                      </TableCell>
                      <TableCell className="tableHead">
                        <span className="head">License</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row, i) => (
                      <TableRow
                        hover
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="tablebody"
                        >
                          <p> {row.name}</p>
                        </TableCell>
                        <TableCell className="tablebody">
                          <div>
                            <p>{row.phone}</p>
                          </div>
                        </TableCell>
                        <TableCell className="tablebody">
                          <p>{row.email}</p>
                        </TableCell>
                        <TableCell className="tablebody">
                          <p>{row.address}</p>
                        </TableCell>
                        <TableCell className="tablebody">
                          <a
                            onClick={() =>
                              handleDownload(`${API_URL}/images/${row.image}`)
                            }
                            href="#!"
                            aria-label="Download image"
                          >
                            <FaDownload />
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handleChangePage}
                variant="outlined"
                color="primary"
              />
            </Box>
          </CustomTabPanel>
        </Box>
      </div>
    </div>
  );
}
