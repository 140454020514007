import React, { useEffect, useState } from "react";
import "./App.css";
import { Link } from "react-router-dom";
import { IoNotifications } from "react-icons/io5";
import { FaLessThan } from "react-icons/fa";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Select, Input, Upload, Flex, message, Space, Image } from "antd";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { HiReceiptRefund } from "react-icons/hi";
import Modal from "@mui/material/Modal";
import { MdClear } from "react-icons/md";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import SelectM from "@mui/material/Select";
import { AiFillEdit } from "react-icons/ai";
import Switch from "@mui/material/Switch";
import { RiDeleteBin6Fill } from "react-icons/ri";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { MdOutlineCloudUpload } from "react-icons/md";
import TextArea from "antd/es/input/TextArea";
import { InboxOutlined } from "@mui/icons-material";
import { DatePicker } from "antd";
import { MdDelete } from "react-icons/md";
import axios from "axios"


import {
  postHeroB,
  getHeroAll,
  getSingleHero,
  patchHero,
  patchReferal,
} from "./Api/Apicalls";
import { ApiEndPoints } from "./Api/Apiendpoints";
import { API_URL } from "./Api/Config";
import { Button } from "antd";
import StaticBanner from "./StaticBanner";

const { Dragger } = Upload;

const { RangePicker } = DatePicker;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  borderRadius: "10px",
  width: "977px",
  p: 4,
};

function createData(Sno, pic2, act) {
  return { Sno, pic2, act };
}

function createDataS(
  Sno,
  Heading,
  Content,
  Ingredients,
  Video,
  Position,
  Action
) {
  return { Sno, Heading, Content, Ingredients, Video, Position, Action };
}

const rows = [createData("01", "", " ")];

const row = [
  createDataS(
    "01",
    "Hyderabad biriyani",
    "Hyderabad biriyani",
    "For the rice....",
    "",
    "01",
    ""
  ),
  createDataS(
    "02",
    "Mutton curry....",
    "Mutton curry....",
    "1 lamb liver....",
    "",
    "02",
    ""
  ),
];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Banner() {
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [referId, setReferId] = useState("");

  const [performance, setPerformance] = React.useState(false);
  const handlemOpen = (id) => {
    setReferId(id);
    setPerformance(true);
  };
  const handlemClose = () => setPerformance(false);

  const [nutretion, setNutretion] = React.useState(false);
  const handleOpenF = () => setNutretion(true);
  const handleCloseF = () => setNutretion(false);

  const [video, setVideo] = React.useState(false);
  const handleOpenK = () => setVideo(true);
  const handleCloseK = () => setVideo(false);

  const [review, setReview] = React.useState(false);
  const handleOpenX = () => setReview(true);
  const handleCloseX = () => setReview(false);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onChange = (value) => {
    console.log("selected", value);
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const handleChange = (info) => {
      if (info.file.status === "uploading") {
        setLoading(true);
        return;
      }
      if (info.file.status === "done") {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (url) => {
          setLoading(false);
          setImageUrl(url);
        });
      }
    };
    const uploadButton = (
      <button
        style={{
          border: 0,
          background: "none",
        }}
        className="banner-select-img"
        type="button"
      >
        {loading ? (
          <LoadingOutlined />
        ) : (
          <UploadOutlined style={{ fontSize: "1.3rem" }} />
        )}
        <div
          style={{
            marginTop: 8,
          }}
        >
          Upload Image
        </div>
      </button>
    );

    const uploadVideo = (
      <button
        style={{
          border: 0,
          background: "none",
        }}
        type="button"
      >
        {loading ? (
          <LoadingOutlined />
        ) : (
          <UploadOutlined style={{ fontSize: "1.3rem" }} />
        )}
        <div
          style={{
            marginTop: 8,
          }}
        >
          Upload Video
        </div>
      </button>
    );

    //Code update
    const [files, setFiles] = useState({ file1: null, file2: null });
    const [imagePreviews, setImagePreviews] = useState({
      preview1: "",
      preview2: "",
    });
    const [postHero, setPostHero] = useState({
      heading: "",
      content: "",
      position: "",
    });
    const [isHerodata, setIsHerodata] = useState([]);
    const [editpostHero, setEditPostHero] = useState(null);

    // const props = (type) => ({
    //   name: "file",
    //   multiple: false,
    //   beforeUpload: (file) => {
    //     // Store the selected file in the state based on type
    //     setFiles((prevFiles) => ({ ...prevFiles, [type]: file }));
    //     // Preview the image
    //     convertToBase64(file).then((base64) => {
    //       setImagePreviews((prevPreviews) => ({ ...prevPreviews, [`preview${type === 'file1' ? 1 : 2}`]: base64 }));
    //     });
    //     return false; // Prevent upload
    //   },
    //   onChange(info) {
    //     const { status } = info.file;
    //     if (status !== "uploading") {
    //       console.log(info.file, info.fileList);
    //     }
    //     if (status === "done") {
    //       message.success(`${info.file.name} file uploaded successfully.`);
    //     } else if (status === "error") {
    //       message.error(`${info.file.name} file upload failed.`);
    //     }
    //   },
    //   onDrop(e) {
    //     console.log("Dropped files", e.dataTransfer.files);
    //   },
    // });

    const props = (type) => ({
      name: "file",
      multiple: false,
      beforeUpload: (file) => {
        setFiles((prevFiles) => ({ ...prevFiles, [type]: file }));
        convertToBase64(file).then((base64) => {
          setImagePreviews((prevPreviews) => ({
            ...prevPreviews,
            [`preview${type === "file1" ? 1 : 2}`]: base64,
          }));
        });
        return false; // Prevent upload
      },
      onChange(info) {
        const { status } = info.file;
        if (status !== "uploading") {
          console.log(info.file, info.fileList);
        }
        if (status === "done") {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
      onDrop(e) {
        console.log("Dropped files", e.dataTransfer.files);
      },
    });

    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };

    // Handle product posting
    // const handlePostHero = async () => {
    //   if (!files.file1 || !files.file2) {
    //     message.error("Both files must be selected");
    //     return;
    //   }

    //   const base64File1 = await convertToBase64(files.file1);
    //   const base64File2 = await convertToBase64(files.file2);

    //   try {
    //     // Create a new FormData object
    //     const formData = new FormData();
    //     // Append form fields to FormData
    //     formData.append("heading", postHero.heading);
    //     formData.append("content", postHero.content);
    //     formData.append("position", postHero.position);
    //     formData.append("image", base64File2); // Store the base64 image for file2
    //     formData.append("background", base64File1); // Store the base64 image for file1

    //     // Make the API request
    //     const response = await postHeroB(ApiEndPoints("postHeroPage"), formData);
    //     console.log(response,"response");

    //     setFiles({ file1: null, file2: null });
    //     setImagePreviews({ preview1: "", preview2: "" });
    //     setPostHero({ heading: "", content: "", position: "" });
    //   } catch (error) {
    //     console.error(error);
    //     alert("Error posting product");
    //   }
    // };

    //GetHero all
    const getAllHero = async () => {
      try {
        const res = await getHeroAll(ApiEndPoints("getHero"));
        setIsHerodata(res.heroPages);
      } catch (err) {
        console.error(err);
      }
    };

    useEffect(() => {
      getAllHero();
    }, []);

    const handlePatchDataget = async (id) => {
      handleOpen();
      try {
        const res = await getSingleHero(ApiEndPoints("getSingleHero"), id);
        setEditPostHero(res.heroPage);
      } catch (err) {
        console.error(err);
      }
    };

    //Delete banner
    const handledelBanner=async(id)=>{
      try{
        const res=await axios.delete(`https://api.meatmarket.live/api/heroPage/${id}`)
        await getAllHero()
      }
      catch(err){
        console.error(err);
      }
    }

    const handlePostHero = async () => {
      // Check if we are editing and no new files are selected
      if (editpostHero && !files.file1 && !files.file2) {
        // Use existing image URLs from the editpostHero object
        postHero.background = editpostHero.background;
        postHero.image = editpostHero.image;
      } else {
        // If not editing, ensure both files are selected
        // if (!files.file1 || !files.file2) {
        //   message.error("Both files must be selected");
        //   return;
        // }
        // Convert the selected files to base64
        // const base64File1 = await convertToBase64(files.file1);
        // const base64File2 = await convertToBase64(files.file2);
        // Set the base64 data to the postHero object
        // postHero.background = base64File1;
        // postHero.image = base64File2;
      }

      try {
        const formData = new FormData();
        formData.append("heading", postHero.heading);
        formData.append("content", postHero.content);
        formData.append("position", postHero.position);
        // Append the images to formData
        if (files.file1) {
          formData.append("background", files.file1);
        } else {
          // Use existing image if no new file1 is provided
          formData.append("background", imagePreviews.preview1);
        }

        if (files.file2) {
          formData.append("image", files.file2);
        } else {
          // Use existing image if no new file2 is provided
          formData.append("image", imagePreviews.preview2);
        }

        let response;
        if (editpostHero) {
          // Update existing entry
          response = await patchHero(
            ApiEndPoints("patchHero"),
            editpostHero._id,
            formData
          );
          await getAllHero();
          handleClose();
        } else {
          // Add new entry
          response = await postHeroB(ApiEndPoints("postHeroPage"), formData);
          handleClose();
        }
        console.log(response, "response");

        // Clear state after successful operation
        setFiles({ file1: null, file2: null });
        setImagePreviews({ preview1: "", preview2: "" });
        setPostHero({ heading: "", content: "", position: "" });
        setEditPostHero(null); // Reset edit state
      } catch (error) {
        console.error(error);
        alert("Error posting hero");
      }
    };

    useEffect(() => {
      if (editpostHero) {
        setPostHero({
          heading: editpostHero.heading,
          content: editpostHero.content,
          position: editpostHero.position,
        });
        setImagePreviews({
          preview1: editpostHero.background, // Existing background image
          preview2: editpostHero.image, // Existing image
        });
      }
    }, [editpostHero]);

    const handleAddnewBtn = () => {
      setFiles({ file1: null, file2: null });
      setImagePreviews({ preview1: "", preview2: "" });
      setPostHero({ heading: "", content: "", position: "" });
      handleOpen();
    };

    const [referBanner, setReferBanner] = useState([]);
    //RefferalBanner
    const fetchreferBannar = async () => {
      try {
        const res = await getHeroAll(ApiEndPoints("getAllReferalBanner"));
        setReferBanner(res.referralBanners);
      } catch (err) {
        console.error(err);
      }
    };

    useEffect(() => {
      fetchreferBannar();
    }, [value]);

    const [imagePreview, setImagePreview] = useState("");
    const [file, setFile] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState("");

    const propsRefer = {
      name: "file",
      multiple: false,
      beforeUpload: (file) => {
        // Store the selected file
        setSelectedFile(file);
        setFile(file);
        // Generate a preview
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => setPreviewImage(reader.result);
        reader.onerror = (error) => console.error(error);
        return false; // Prevent upload
      },
      onChange(info) {
        const { status } = info.file;
        if (status !== "uploading") {
          console.log(info.file, info.fileList);
        }
        if (status === "done") {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
      onDrop(e) {
        console.log("Dropped files", e.dataTransfer.files);
      },
    };

    // Handle product posting
    const handlePatchProduct = async () => {
      if (!file) {
        message.error("No file selected");
        return;
      }

      // const base64File = await convertToBase64(file);
      // console.log(base64File,"base64File");
      try {
        // Create a new FormData object
        const formData = new FormData();
        formData.append("image", file);

        // Make the API request
        const response = await patchReferal(
          ApiEndPoints("patchReferal"),
          referId,
          formData
        );
        setFile(null);
        setImagePreview("");
        // console.log(response, "res");
      } catch (error) {
        console.error(error);
        alert("Error posting product");
      }
    };

    const [isChecked, setIsChecked] = useState(false);

    const handleToggle = (event) => {
      console.log(event.target.checked);

      const checked = event.target.checked;
      setIsChecked(checked);
    };


    const [fileList, setFileList] = useState([]);
    const [selectedTimes, setSelectedTimes] = useState(null);
  
    // Handle file upload
    const handleChangeM = (info) => {
      if (info.fileList) {
        setFileList(info.fileList);
      }
    };
  
    // Handle date range change
    const onRangeChange = (dates) => {
      setSelectedTimes(dates);
    };
  
    // Handle upload success message
    const handleUploadSuccess = () => {
      message.success('Images uploaded successfully');
    };

    return (
      <div className="mx-3 ">
        <div className="banner-combine">
          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <div>
              <h1 className="h1">Banners</h1>
            </div>
            {value === 1 ? (
              ""
            ) : (
              <>
                <div>
                  {!isChecked&&
                  <><button
                        style={{
                          color: "white",
                          backgroundColor: "#0d354f",
                          border: "none",
                          padding: "5px 50px",
                          borderRadius: "5px",
                          marginRight: "50px",
                        }}
                        onClick={handleAddnewBtn}
                      >
                        Add New
                      </button></>}
                  <div className="mb-4">
                    <Switch
                      checked={isChecked}
                      onChange={handleToggle}
                      className="toggle-on"
                      inputProps={{ "aria-label": "controlled" }}
                      size="small"
                    />(For temporary banner)
                  </div>
                </div>
              </>
            )}
          </div>
          {isChecked ? (
            <>
              <StaticBanner/>
            </>
          ) : (
            <div>
              <Box sx={{ width: "100%" }}>
                <Box>
                  <Tabs
                    value={value}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Hero page" {...a11yProps(0)} />
                    {/* <Tab
                  label="Refferal banner"
                  {...a11yProps(1)}
                  className="gap-tab"
                /> */}
                    {/* <Tab label="Nutrition banner" {...a11yProps(2)} className="gap-tab"/>
                <Tab label="Videos" {...a11yProps(3)} className="gap-tab"/>
                <Tab label="Reviews Background" {...a11yProps(4)} /> */}
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <Paper
                    sx={{
                      width: "95.5%",
                      overflow: "hidden",
                      border: "1px solid lightgrey",
                    }}
                    className="table-width"
                  >
                    <TableContainer
                      component={Paper}
                      className="today-order-table"
                    >
                      <Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table-contain"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell className="tableHead">
                              <span className="head">S.no</span>
                            </TableCell>
                            <TableCell className="tableHead">
                              <span className="head">Heading</span>
                            </TableCell>
                            <TableCell className="tableHead">
                              <span className="head">Content</span>
                            </TableCell>
                            <TableCell className="tableHead">
                              <span className="head">Background</span>
                            </TableCell>
                            <TableCell className="tableHead">
                              <span className="head">Image</span>
                            </TableCell>
                            {/* <TableCell className="tableHead">
                              <span className="head">Position</span>
                            </TableCell> */}
                            <TableCell className="tableHead">
                              <span className="head">Action</span>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {isHerodata
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, i) => (
                              <TableRow
                                hover
                                key={i}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="tablebody"
                                >
                                  <p> {i + 1}</p>
                                </TableCell>
                                <TableCell className="tablebody">
                                  <div>
                                    <p>{row.heading}</p>
                                  </div>
                                </TableCell>
                                <TableCell className="tablebody">
                                  <p> {row.content}</p>
                                </TableCell>

                                <TableCell className="tablebody">
                                  <div>
                                    <img
                                      src={`${API_URL}/images/${row.background}`}
                                      style={{ width: "50px", height: "50px" }}
                                      alt="img1"
                                    />
                                  </div>
                                </TableCell>

                                <TableCell className="tablebody">
                                  <div>
                                    <img
                                      src={`${API_URL}/images/${row.image}`}
                                      style={{ width: "50px", height: "50px" }}
                                      alt="img1"
                                    />
                                  </div>
                                </TableCell>
                                {/* <TableCell className="tablebody">
                                  <div>
                                    <p>{row.position}</p>
                                  </div>
                                </TableCell> */}

                                <TableCell className="tablebody">
                                  <div className="banner-icon-combine">
                                    <div className="d-flex"
                                    >
                                      <AiFillEdit className="banner-icon" onClick={() =>
                                        handlePatchDataget(row._id)
                                      }/>
                                     <MdDelete className="banner-icon" onClick={()=>handledelBanner(row._id)}/>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    {/* ACTION */}

                    <div>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box
                          sx={style}
                          className="model-banner"
                          style={{ height: "90vh", overflow: "auto" }}
                        >
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <h3>Banners</h3>
                              </div>
                              <div>
                                <h6>
                                  <MdClear
                                    onClick={handleClose}
                                    style={{ cursor: "pointer" }}
                                  />
                                </h6>
                              </div>
                            </div>
                          </Typography>
                          <Row className="row-banner">
                            <Col xs={6}>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "20px",
                                  flexDirection: "column",
                                }}
                              >
                                <div>
                                  <p>Heading</p>
                                  <Input
                                    style={{
                                      width: "350px",
                                      height: "35px",
                                      color: "black",
                                    }}
                                    value={postHero.heading}
                                    placeholder=" "
                                    className="select-pending banner-select"
                                    onChange={(e) =>
                                      setPostHero({
                                        ...postHero,
                                        heading: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                                <div>
                                  <p>Content</p>
                                  <textarea
                                    className="banner-textarea banner-select-1"
                                    rows={5}
                                    defaultValue={postHero.content}
                                    onChange={(e) =>
                                      setPostHero({
                                        ...postHero,
                                        content: e.target.value,
                                      })
                                    }
                                  ></textarea>
                                </div>
                                <div>
                                  <p>Position</p>
                                  <Input
                                    style={{
                                      width: "350px",
                                      height: "35px",
                                      color: "black",
                                    }}
                                    placeholder=" "
                                    className="select-pending banner-select"
                                    value={postHero.position}
                                    onChange={(e) =>
                                      setPostHero({
                                        ...postHero,
                                        position: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col xs={6}>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "20px",
                                  flexDirection: "column",
                                  padding: "0px 40px",
                                }}
                              >
                                <div>
                                  <p>Background (Recommended: 1440*700 px)</p>
                                  <Dragger
                                    {...props("file1")}
                                    className="dragger-box"
                                  >
                                    <p className="ant-upload-drag-icon mb-0">
                                      <MdOutlineCloudUpload
                                        style={{
                                          color: "#0D354F",
                                          fontSize: "3rem",
                                        }}
                                      />
                                    </p>
                                    <p className="ant-upload-text mb-0">
                                      Click or drag file to this area to upload
                                    </p>
                                  </Dragger>
                                  {/* {imagePreviews.preview1 && (
                                <img
                                  src={`${API_URL}/images/${imagePreviews.preview1}`}
                                  alt="preview1"
                                  style={{ width: "100%" }}
                                />
                              )} */}

                                  {imagePreviews.preview1 ? (
                                    <div style={{ marginBottom: "20px" }}>
                                      <img
                                        src={
                                          files.file1
                                            ? URL.createObjectURL(files.file1)
                                            : `${API_URL}/images/${imagePreviews.preview1}`
                                        }
                                        alt="Product Preview"
                                        style={{
                                          maxWidth: "300px",
                                          maxHeight: "300px",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div style={{ marginBottom: "20px" }}>
                                      <img
                                        src={`${API_URL}/images/${imagePreviews.preview1}`}
                                        alt="Product Preview"
                                        style={{
                                          maxWidth: "300px",
                                          maxHeight: "300px",
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div>
                                  <p>Background (Recommended: 300*350 px)</p>
                                  <Dragger
                                    {...props("file2")}
                                    className="dragger-box"
                                  >
                                    <p className="ant-upload-drag-icon mb-0">
                                      <MdOutlineCloudUpload
                                        style={{
                                          color: "#0D354F",
                                          fontSize: "3rem",
                                        }}
                                      />
                                    </p>
                                    <p className="ant-upload-text mb-0">
                                      Click or drag file to this area to upload
                                    </p>
                                  </Dragger>
                                  {imagePreviews.preview2 ? (
                                    <div style={{ marginBottom: "20px" }}>
                                      <img
                                        src={
                                          files.file2
                                            ? URL.createObjectURL(files.file2)
                                            : `${API_URL}/images/${imagePreviews.preview2}`
                                        }
                                        alt="Product Preview"
                                        style={{
                                          maxWidth: "300px",
                                          maxHeight: "300px",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div style={{ marginBottom: "20px" }}>
                                      <img
                                        src={`${API_URL}/images/${imagePreviews.preview2}`}
                                        alt="Product Preview"
                                        style={{
                                          maxWidth: "300px",
                                          maxHeight: "300px",
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <div
                            style={{
                              marginTop: "40px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              style={{
                                color: "white",
                                backgroundColor: "#0d354f",
                                border: "none",
                                padding: "5px 50px",
                                borderRadius: "5px",
                                marginRight: "50px",
                              }}
                              onClick={handlePostHero}
                            >
                              Submit
                            </button>
                          </div>
                        </Box>
                      </Modal>
                    </div>
                  </Paper>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <Paper
                    sx={{
                      width: "95.5%",
                      overflow: "hidden",
                      border: "1px solid lightgrey",
                    }}
                    className="table-width"
                  >
                    <TableContainer
                      component={Paper}
                      className="today-order-table"
                    >
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell className="tableHead">
                              <span className="head">S.no</span>
                            </TableCell>
                            <TableCell className="tableHead">
                              <span className="head">Image</span>
                            </TableCell>
                            <TableCell className="tableHead">
                              <span className="head">Action</span>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {referBanner.map((row, i) => (
                            <TableRow
                              key={i}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className="tablebody"
                                style={{ width: "40%" }}
                              >
                                <p> {i + 1}</p>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                className="tablebody"
                              >
                                <div>
                                  <img
                                    src={`${API_URL}/images/${row.image}`}
                                    alt="img1"
                                    style={{ width: "50%", height: "70px" }}
                                  />
                                </div>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                className="tablebody"
                              >
                                <div onClick={() => handlemOpen(row._id)}>
                                  <AiFillEdit className="banner-icon" />
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    {/* ACTION */}
                    <div>
                      <Modal
                        open={performance}
                        onClose={handlemClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style} className="model-banner">
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <h3>Banners</h3>
                              </div>

                              <div>
                                <h6>
                                  <MdClear
                                    onClick={handlemClose}
                                    style={{ cursor: "pointer" }}
                                  />
                                </h6>
                              </div>
                            </div>
                          </Typography>
                          <Row style={{ padding: "0px 65px" }}>
                            <Col xs={6}>
                              <div>
                                <label>
                                  Background(Recommanded: 1440*300 px )
                                </label>
                                <Dragger
                                  {...propsRefer}
                                  className="dragger-box"
                                >
                                  <p className="ant-upload-drag-icon mb-0">
                                    <MdOutlineCloudUpload
                                      style={{
                                        color: "#0D354F",
                                        fontSize: "3rem",
                                      }}
                                    />
                                  </p>
                                  <p className="ant-upload-text mb-0">
                                    Click or drag file to this area to upload
                                  </p>
                                </Dragger>
                                {previewImage && (
                                  <img
                                    src={previewImage}
                                    alt="Preview"
                                    style={{ width: "100%", marginTop: "10px" }}
                                  />
                                )}
                              </div>
                            </Col>
                            <Col xs={6}></Col>
                          </Row>
                          <div
                            style={{
                              marginTop: "40px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              style={{
                                color: "white",
                                backgroundColor: "#0d354f",
                                border: "none",
                                padding: "5px 50px",
                                borderRadius: "5px",
                                marginRight: "50px",
                              }}
                              onClick={() => handlePatchProduct()}
                            >
                              Submit
                            </button>
                          </div>
                        </Box>
                      </Modal>
                    </div>
                  </Paper>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <Paper
                    sx={{
                      width: "95.5%",
                      overflow: "hidden",
                      border: "1px solid lightgrey",
                    }}
                    className="table-width"
                  >
                    <TableContainer
                      component={Paper}
                      className="today-order-table"
                    >
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell className="tableHead">
                              <span className="head">S.no</span>
                            </TableCell>
                            <TableCell className="tableHead">
                              <span className="head">Image</span>
                            </TableCell>
                            <TableCell className="tableHead">
                              <span className="head">Action</span>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <TableRow
                              key={row.Sno}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className="tablebody"
                                style={{ width: "40%" }}
                              >
                                <p> {row.Sno}</p>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                className="tablebody"
                              >
                                <div>
                                  <img
                                    src="/assests/meatimg.png"
                                    alt="img1"
                                    style={{ width: "50%", height: "70px" }}
                                  />
                                </div>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                className="tablebody"
                              >
                                <div onClick={handleOpenF}>
                                  <AiFillEdit className="banner-icon" />
                                  <p> {row.Action}</p>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    {/* ACTION */}
                    <div>
                      <Modal
                        open={nutretion}
                        onClose={handleCloseF}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style} className="model-banner">
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <h3>Banners</h3>
                              </div>

                              <div>
                                <h6>
                                  <MdClear
                                    onClick={handleCloseF}
                                    style={{ cursor: "pointer" }}
                                  />
                                </h6>
                              </div>
                            </div>
                          </Typography>
                          <Row style={{ padding: "0px 65px" }}>
                            <Col xs={6}>
                              <div>
                                <label>Background( 1440*940 px )</label>
                                <Flex gap="middle" wrap>
                                  <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    showUploadList={false}
                                    action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                  >
                                    {imageUrl ? (
                                      <img
                                        src={imageUrl}
                                        alt="avatar"
                                        style={{
                                          width: "100%",
                                        }}
                                      />
                                    ) : (
                                      uploadVideo
                                    )}
                                  </Upload>
                                </Flex>
                              </div>
                            </Col>
                            <Col xs={6}></Col>
                          </Row>
                          <div
                            style={{
                              marginTop: "40px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              style={{
                                color: "white",
                                backgroundColor: "#0d354f",
                                border: "none",
                                padding: "5px 50px",
                                borderRadius: "5px",
                                marginRight: "50px",
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </Box>
                      </Modal>
                    </div>
                  </Paper>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                  <Paper
                    sx={{
                      width: "95.5%",
                      overflow: "hidden",
                      border: "1px solid lightgrey",
                    }}
                    className="table-width"
                  >
                    <TableContainer
                      component={Paper}
                      className="today-order-table"
                    >
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell className="tableHead">
                              <span className="head">S.no</span>
                            </TableCell>
                            <TableCell className="tableHead">
                              <span className="head">Heading</span>
                            </TableCell>
                            <TableCell className="tableHead">
                              <span className="head">Content</span>
                            </TableCell>
                            <TableCell className="tableHead">
                              <span className="head">Ingredients</span>
                            </TableCell>
                            <TableCell className="tableHead">
                              <span className="head">Video</span>
                            </TableCell>
                            <TableCell className="tableHead">
                              <span className="head">Position</span>
                            </TableCell>
                            <TableCell className="tableHead">
                              <span className="head">Action</span>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row.map((row) => (
                            <TableRow
                              key={row.Sno}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className="tablebody"
                              >
                                <p> {row.Sno}</p>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                className="tablebody"
                              >
                                <p> {row.Heading}</p>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                className="tablebody"
                              >
                                <p> {row.Content}</p>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                className="tablebody"
                              >
                                <p> {row.Ingredients}</p>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                className="tablebody"
                              >
                                <div>
                                  <img
                                    src="/assests/meatimg.png"
                                    alt="img1"
                                    style={{ width: "50%", height: "70px" }}
                                  />
                                  {row.Pic}
                                </div>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                className="tablebody"
                              >
                                <p> {row.Position}</p>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                className="tablebody"
                              >
                                <div className="banner-icon-combine">
                                  <div onClick={handleOpenK}>
                                    <AiFillEdit className="banner-icon" />
                                  </div>
                                  &nbsp;&nbsp;&nbsp;
                                  <div>
                                    <RiDeleteBin6Fill className="banner-icon" />
                                    {row.Action}
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* ACTION */}
                    <div>
                      <Modal
                        open={video}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style} className="model-banner">
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <h3>Banners</h3>
                              </div>

                              <div>
                                <h6>
                                  <MdClear
                                    onClick={handleCloseK}
                                    style={{ cursor: "pointer" }}
                                  />
                                </h6>
                              </div>
                            </div>
                          </Typography>
                          <Row className="row-banner">
                            <Col xs={6} md={6}>
                              <label>Heading</label>

                              <Input
                                style={{
                                  width: "350px",
                                  height: "35px",
                                  color: "black",
                                }}
                                placeholder=" "
                                className="select-pending banner-select"
                              />

                              <label>Content</label>

                              <textarea
                                className="banner-textarea banner-select-1"
                                rows={5}
                              ></textarea>

                              <label>Ingredients</label>

                              <textarea
                                className="banner-textarea banner-select-1"
                                rows={5}
                              ></textarea>
                            </Col>
                            <Col xs={6} md={6}>
                              <label>Position</label>

                              <Input
                                style={{
                                  width: "100%",
                                  height: "35px",
                                  color: "black",
                                }}
                                placeholder=" "
                                className="select-pending banner-select"
                              />

                              <label>Video</label>
                              <Flex gap="middle" wrap>
                                <Upload
                                  name="avatar"
                                  listType="picture-card"
                                  showUploadList={false}
                                  action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                  beforeUpload={beforeUpload}
                                  onChange={handleChange}
                                  className="banner-select-img banner-select"
                                >
                                  {imageUrl ? (
                                    <img
                                      src={imageUrl}
                                      alt="avatar"
                                      style={{
                                        width: "100%",
                                      }}
                                      className="banner-select-img"
                                    />
                                  ) : (
                                    uploadVideo
                                  )}
                                </Upload>
                              </Flex>
                            </Col>
                          </Row>
                          <div
                            style={{
                              marginTop: "40px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              style={{
                                color: "white",
                                backgroundColor: "#0d354f",
                                border: "none",
                                padding: "5px 50px",
                                borderRadius: "5px",
                                marginRight: "50px",
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </Box>
                      </Modal>
                    </div>
                  </Paper>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={4}>
                  <Paper
                    sx={{
                      width: "95.5%",
                      overflow: "hidden",
                      border: "1px solid lightgrey",
                    }}
                    className="table-width"
                  >
                    <TableContainer
                      component={Paper}
                      className="today-order-table"
                    >
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell className="tableHead">
                              <span className="head">S.no</span>
                            </TableCell>
                            <TableCell className="tableHead">
                              <span className="head">Image</span>
                            </TableCell>
                            <TableCell className="tableHead">
                              <span className="head">Action</span>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <TableRow
                              key={row.Sno}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className="tablebody"
                                style={{ width: "40%" }}
                              >
                                <p> {row.Sno}</p>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                className="tablebody"
                              >
                                <div>
                                  <img
                                    src="/assests/meatimg.png"
                                    alt="img1"
                                    style={{ width: "50%", height: "70px" }}
                                  />
                                </div>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                className="tablebody"
                              >
                                <div onClick={handleOpenX}>
                                  <AiFillEdit className="banner-icon" />
                                  <p> {row.Action}</p>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    {/* ACTION */}
                    <div>
                      <Modal
                        open={review}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style} className="model-banner">
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <h3>Banners</h3>
                              </div>

                              <div>
                                <h6>
                                  <MdClear
                                    onClick={handleCloseX}
                                    style={{ cursor: "pointer" }}
                                  />
                                </h6>
                              </div>
                            </div>
                          </Typography>
                          <Row style={{ padding: "0px 65px" }}>
                            <Col xs={6}>
                              <div>
                                <label>Background( 1440*600 px )</label>
                                <Flex gap="middle" wrap>
                                  <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    showUploadList={false}
                                    action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                  >
                                    {imageUrl ? (
                                      <img
                                        src={imageUrl}
                                        alt="avatar"
                                        style={{
                                          width: "100%",
                                        }}
                                      />
                                    ) : (
                                      uploadVideo
                                    )}
                                  </Upload>
                                </Flex>
                              </div>
                            </Col>
                            <Col xs={6}></Col>
                          </Row>
                          <div
                            style={{
                              marginTop: "40px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              style={{
                                color: "white",
                                backgroundColor: "#0d354f",
                                border: "none",
                                padding: "5px 50px",
                                borderRadius: "5px",
                                marginRight: "50px",
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </Box>
                      </Modal>
                    </div>
                  </Paper>
                </CustomTabPanel>
              </Box>
            </div>
          )}
        </div>
      </div>
    );
  }
}
