import React, { useEffect, useState } from "react";
import { DatePicker, Radio } from "antd";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { allOrders } from "../Api/Apicalls";
import { ApiEndPoints } from "../Api/Apiendpoints";
import moment from 'moment'; // Ensure moment.js is installed and imported

const Requirment = () => {
  const [value, setValue] = useState("07:00am-11:00am");
  const [orders, setOrders] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date()); 

  const onChange = (date, dateString) => {
    // Handle the date selection and update state
    setSelectedDate(date ? moment(dateString, "YYYY-MM-DD").toDate() : null);
  };

  const onChangeradio = (e) => {
    setValue(e.target.value);
  };

  const getAllorders = async () => {
    try {
      const response = await allOrders(ApiEndPoints("getAllorders"));
      setOrders(response.orders);
      filterAndProcessOrders(response.orders, selectedDate, value);
    } catch (err) {
      console.error(err);
    }
  };

  const filterAndProcessOrders = (orders, selectedDate, selectedSlot) => {
    
    let selectDate=moment(selectedDate).format('DD-MM-YYYY')

    const productMap = {};

    orders.forEach(order => {
      const orderDate = new Date(order.deliveryDate);
      let d=moment(orderDate).format('DD-MM-YYYY')
      // Filter by date and slot
      if ((!selectDate ||  d=== selectDate) && (!selectedSlot || order.deliverySlot === selectedSlot)) {
        order.lineItems.forEach(item => {
          const { product, quantity } = item;
          if (productMap[product._id]) {
            productMap[product._id].totalOrders += 1;
            productMap[product._id].totalQuantity += quantity;
          } else {
            productMap[product._id] = {
              name: product.name,
              totalOrders: 1,
              totalQuantity: quantity
            };
          }
        });
      }
    });

    const processedData = Object.values(productMap);
    setFilteredData(processedData);
  };

  useEffect(() => {
    getAllorders();
  }, []);

  useEffect(() => {
    filterAndProcessOrders(orders, selectedDate, value);
  }, [selectedDate, value, orders]);

  return (
    <>
      <section className="px-4 mx-3">
        <h2 className="py-3 mb-0">Product Requirement</h2>
        <div className="d-flex justify-content-start align-items-center">
          <div>
            <label>Select Date</label>
            <br />
            <DatePicker 
              onChange={onChange} 
              format="YYYY-MM-DD" // Ensure the format matches your needs
            />
          </div>
          <div className="mx-3">
            <label>Select Slot</label>
            <br />
            <Radio.Group onChange={onChangeradio} value={value}>
              <Radio value={"07:00am-11:00am"}>Slot (7am To 11am)</Radio>
              <Radio value={"05:00pm-09:00pm"}>Slot (5pm To 9pm)</Radio>
            </Radio.Group>
          </div>
        </div>
        <div className="pt-3">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Total Orders</TableCell>
                  <TableCell>Total Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((product, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {product.name}
                    </TableCell>
                    <TableCell>{product.totalOrders}</TableCell>
                    <TableCell>{product.totalQuantity} G</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {filteredData.length<=0&&<h3 className="text-center pt-3">No orders available</h3>}
        </div>
      </section>
    </>
  );
};

export default Requirment;
