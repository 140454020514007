import { Button } from "@mui/material";
import { Input } from "antd";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { IoMdPerson } from "react-icons/io";
import { Link } from "react-router-dom";
import {forgetpassUser} from "./Api/Apicalls";
import {ApiEndPoints} from "./Api/Apiendpoints"

const ForgetPass = () => {

  const [mail,setMail]=useState("")
  const [resetMessage, setresetMessage] = useState("");
const handlePassword=async()=>{
  if (mail === "") {
    setresetMessage("Fill email!");
  } else {
    setresetMessage("");
    try {
      const forpassResp = await forgetpassUser(
        ApiEndPoints("forgetPassword"),
        {email:mail}
      );
      console.log(forpassResp, "forpassResp");
      setresetMessage(forpassResp.message);
    } catch (error) {
      console.log(error);
    }
  }
}

    return (
        <>
             <section className="login-bg">
        <Container>
          <div className="row d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
              <div className="py-3 login-box">
                <h2 className="title text-center topic">Welcome</h2>
                <p className=" text-center log">
                Reset Your Password
                </p>
                <label className="label py-2 sub-t">Email id</label>
                <br />
                <Input
                  size="large"
                  suffix={<IoMdPerson style={{color:'grey'}}/>}
                  placeholder="Enter your email id"
                  style={{ height: "45px", width: "100%" }}
                  onChange={(e)=>setMail(e.target.value)}
                />
                <p className="text-center pt-2" style={{color:"red"}}>{resetMessage}</p>
                <div className="text-center log-btn pt-4 pb-2">
                    <button className="but" onClick={handlePassword}><Link style={{color:"#fff",textDecoration:"none"}}>Reset</Link></button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
        </>
    )
}

export default ForgetPass