import axios from 'axios';
import { API_URL } from "./Config";

axios.defaults.withCredentials = true;

const getAuthToken = () => {
    return localStorage.getItem('dashtoken');
  };
  
  const getHeaders = () => {
    const token = getAuthToken();
    // console.log(token,"token");
    return {
      'Content-Type': 'application/json',
      ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
    };
  };


// Login
export const loginUser = async (endpoint, data)=> {
    try {
      const response = await axios.post(new URL(endpoint, API_URL).toString(), data, {
        withCredentials: true
      });
      return response.data;
    } catch (error) {
      console.error('Failed to post data:', error);
      throw error;
    }
};


//Post product
export const postProducts = async (endpoint, data) => {
    const token = getAuthToken();
    try {
      const response = await axios.post(new URL(endpoint, API_URL).toString(), data, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Failed to post data:', error);
      throw error;
    }
  };


  //All Products

export const allProducts = async (endpoint,page) => {
    try {
      const response = await axios.get(new URL(endpoint+"?"+"page="+page, API_URL).toString(), {
        headers: getHeaders(),
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch data:', error);
      throw error;
    }
  };


  //Update product
  export const updateProduct = async (endpoint,productId,data) => {
    const token = getAuthToken();
    try {
      const response = await axios.patch(new URL(endpoint+`/${productId}`,API_URL).toString(),data, {
       headers: {
        'Authorization': `Bearer ${token}`,
       },
      });
      return response.data;
    } catch (error) {
      console.error('Failed to post data:', error);
      throw error;
    }
  };


  //Get single product
  export const getProduct = async (endpoint,productId) => {
    try {
      const response = await axios.get(new URL(endpoint+`/${productId}`,API_URL).toString(), {
       headers: getHeaders()
      });
      return response.data;
    } catch (error) {
      console.error('Failed to post data:', error);
      throw error;
    }
  };


  //Get all orders
  export const allOrders = async (endpoint,page) => {
    try {
      const response = await axios.get(new URL(endpoint, API_URL).toString(), {
        headers: getHeaders(),
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch data:', error);
      throw error;
    }
  };

  //Get all requests
  export const getAllrequest = async (endpoint,page) => {
    try {
      const response = await axios.get(new URL(endpoint+"?"+"page="+page, API_URL).toString(), {
        headers: getHeaders(),
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch data:', error);
      throw error;
    }
  };

  //Get single order
  export const getOrder = async (endpoint,productId) => {
    try {
      const response = await axios.get(new URL(endpoint+`/${productId}`,API_URL).toString(), {
       headers: getHeaders()
      });
      return response.data;
    } catch (error) {
      console.error('Failed to post data:', error);
      throw error;
    }
  };

  //PatchOrder refund
  export const patchRefund = async (endpoint,productId,data) => {
    try {
      const response = await axios.patch(new URL(endpoint+`/${productId}`,API_URL).toString(),data, {
       headers: getHeaders()
      });
      return response.data;
    } catch (error) {
      console.error('Failed to post data:', error);
      throw error;
    }
  };


  //Post Hero data
  export const postHeroB = async (endpoint, data) => {
    const token = getAuthToken();
    try {
      const response = await axios.post(new URL(endpoint, API_URL).toString(), data, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Failed to post data:', error);
      throw error;
    }
  };

  //Get Hero
  export const getHeroAll = async (endpoint) => {
    try {
      const response = await axios.get(new URL(endpoint, API_URL).toString(), {
        headers: getHeaders(),
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch data:', error);
      throw error;
    }
  };
//Get Single Hero
  export const getSingleHero = async (endpoint,productId) => {
    try {
      const response = await axios.get(new URL(endpoint+`/${productId}`,API_URL).toString(), {
       headers: getHeaders()
      });
      return response.data;
    } catch (error) {
      console.error('Failed to post data:', error);
      throw error;
    }
  };


  //PatchHero
  export const patchHero = async (endpoint,productId,data) => {
    let token=getAuthToken()
    try {
      const response = await axios.patch(new URL(endpoint+`/${productId}`,API_URL).toString(),data, {
       headers: {
        'Authorization': `Bearer ${token}`,
      }
      });
      return response.data;
    } catch (error) {
      console.error('Failed to post data:', error);
      throw error;
    }
  };


  //Patch Referal
  export const patchReferal = async (endpoint,productId,data) => {
    let token=getAuthToken()
    try {
      const response = await axios.patch(new URL(endpoint+`/${productId}`,API_URL).toString(),data, {
       headers: {
        'Authorization': `Bearer ${token}`,
      },
      });
      return response.data;
    } catch (error) {
      console.error('Failed to post data:', error);
      throw error;
    }
  };
  


  //Report get
  export const getReport= async (endpoint,year,month,day) => {
    try {
      const response = await axios.get(new URL(endpoint+`?year=${year}&month=${month}&day=${day}`,API_URL).toString(), {
       headers: getHeaders()
      });
      return response.data;
    } catch (error) {
      console.error('Failed to post data:', error);
      throw error;
    }
  };

  //Patch Orders
  export const patchOrder = async (endpoint,productId,data) => {
    try {
      const response = await axios.patch(new URL(endpoint+`/${productId}`,API_URL).toString(),data, {
       headers: getHeaders()
      });
      return response.data;
    } catch (error) {
      console.error('Failed to post data:', error);
      throw error;
    }
  };


  // Delete Product
  export const deleteProduct = async (endpoint,productId) => {
    try {
      const response = await axios.delete(new URL(endpoint+`/${productId}`,API_URL).toString(), {
       headers: getHeaders()
      });
      return response.data;
    } catch (error) {
      console.error('Failed to post data:', error);
      throw error;
    }
  };

  //Report by month
  export const getReportMonth= async (endpoint,year,month) => {
    try {
      const response = await axios.get(new URL(endpoint+`?year=${year}&month=${month}`,API_URL).toString(), {
       headers: getHeaders()
      });
      return response.data;
    } catch (error) {
      console.error('Failed to post data:', error);
      throw error;
    }
  };

  
// ForgetPassword
export const forgetpassUser = async (
  endpoint,
  data
) => {
  try {
    const response = await axios.post(
      new URL(endpoint, API_URL).toString(),
      data,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

  