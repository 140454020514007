import React, { useEffect, useState } from "react";
import "./App.css";
import { Link, useNavigate } from "react-router-dom";
import { IoNotifications } from "react-icons/io5";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Select, Input, Upload, Flex, message, Space, Progress,DatePicker } from "antd";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { HiReceiptRefund } from "react-icons/hi";
import Modal from "@mui/material/Modal";
import { MdClear } from "react-icons/md";
import Typography from "@mui/material/Typography";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { allOrders } from "./Api/Apicalls";
import { ApiEndPoints } from "./Api/Apiendpoints";
import { patchRefund, patchOrder } from "./Api/Apicalls";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import { TablePagination } from "@mui/material";
import { API_URL } from "./Api/Config";
import axios from "axios"


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  padding: "20px",
  borderRadius: "10px",
  p: 5,
};

export default function Order() {
  const [value, setValue] = React.useState(0);
  const [deliverySlot, setDeliverySlot] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [ogData, setOgdata] = useState([]);
  const [orderData, setOrderData] = useState([]);


  const getAllorders = async () => {
    try {
      const response = await allOrders(ApiEndPoints("getAllorders"));
      const sortedOrders = response.orders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      const currentOrders = sortedOrders.filter(order => !order.isCancelled);
      const cancelledOrders = sortedOrders.filter(order => order.isCancelled);

      // Update state based on the tab value
      if (value === 0) {
        // Assuming 0 is for current orders
        setOgdata(currentOrders);
        setOrderData(currentOrders);
      } else if (value === 1) {
        // Assuming 1 is for cancelled orders
        setOgdata(cancelledOrders);
        setOrderData(cancelledOrders);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAllorders();
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [refundDetails, setrefundDetails] = useState(null);
  const [filterDate,setFilterDate]=useState("")


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [refund, setRefund] = React.useState(false);
  const handleOpenL = (val) => {
    setRefund(true);
    setrefundDetails(val);
  };
  const handleCloseL = () => setRefund(false);
  const [getAllFransData,setgetallFransData]=useState([])


  const [performance, setPerformance] = React.useState(false);
  const handlemOpen = () => setPerformance(true);
  const handlemClose = () => setPerformance(false);

  const onChange = (value) => {
    console.log("selected", value);
  };

  const navigate = useNavigate();
  const handleOrderDetails = (id) => {
    navigate(`/detail/${id}`);
  };

  //RefundUpdate
  const handleRefund = async (id) => {
    try {
      const response = await patchRefund(ApiEndPoints("patchOrder"), id, {
        refundStatus: "completed",
      });
      getAllorders();
      setRefund(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeliverySlotChange = (value) => {
    setDeliverySlot(value);
  };

  const handlePaymentStatusChange = (value) => {
    setPaymentStatus(value);
  };


  const filterForcurrentdata = () => {
    
    const filteredData = ogData.filter((data) => {
      return (
        (deliverySlot === "" || deliverySlot === data.deliverySlot) &&
        (paymentStatus === "" || paymentStatus === data.paymentMethod)&&(filterDate===""||filterDate===moment(data.deliveryDate).format("YYYY-MM-DD"))
      );
    });
    setOrderData(filteredData);
  };


  const handleDateChange = (date, dateString) => {    
  setFilterDate(dateString);
};

  const filterForcanceldata = () => {
    const filteredData = ogData.filter((data) => {
      return (
        (deliverySlot === "" || deliverySlot === data.deliverySlot) &&
        (paymentStatus === "" || paymentStatus === data.paymentStatus)
      );
    });
    setOrderData(filteredData);
  };

  const handleChangeDelivery = (data, id) => {
    patchDevilry(data, id);
  };

  const handleAssign=async(value,ids)=>{
    try{
    const response = await patchOrder(ApiEndPoints("patchOrder"), ids, {
      assignedFranchise: value,
    });
  }
  catch(error){
    alert("Something went wrong try again later!")
  }
  }

  const patchDevilry = async (value, ids) => {
    try {

      if(value==="completed"){
        const response = await patchOrder(ApiEndPoints("patchOrder"), ids, {
          status: value,
          paymentStatus:"paid",
          deliveryDate:new Date()
        });
      }
      else{
        const response = await patchOrder(ApiEndPoints("patchOrder"), ids, {
          status: value,
          deliveryDate:new Date()
        });
      }
      getAllorders();
    } catch (error) {
      console.error(error);
    }
  };

  const [fransID,setFransID]=useState([])
  const getAllFrans = async () => {
    const ids = [];
    try {
      const response = await axios.get(`${API_URL}/api/franchise`);
      const franchises = response.data.franchises || [];
      
      const ids = franchises.map((val) => val._id);
      setgetallFransData(franchises);
      setFransID(ids);
    } catch (error) {
      console.error("Error fetching franchises:", error);
      alert("Something went wrong!");
    }
  };
  

  useEffect(()=>{
    getAllFrans()
  },[])

  
  return (
    <div className="mx-3">
      <div className="order-combine">
        <div className="d-flex" style={{ justifyContent: "space-between" }}>
          <div>
            <h1 className="h1">Orders</h1>
          </div>

          {/* <div>
            <button
              style={{
                color: "white",
                backgroundColor: "#0d354f",
                border: "none",
                padding: "5px 50px",
                borderRadius: "5px",
                marginRight: "50px",
              }}
            >
              Share
            </button>
          </div> */}
        </div>

        <Box sx={{ width: "100%" }}>
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Current Order" {...a11yProps(0)} />
              <Tab
                label="Cancelled Order"
                {...a11yProps(1)}
                className="gap-tab"
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <div
              className="d-flex product-search-combine"
              style={{ marginTop: "5px" }}
            >
              <Space wrap>
                <Select
                  defaultValue="Select by delivery slot"
                  className="product-search-1"
                  onChange={handleDeliverySlotChange}
                  options={[
                    {
                      value: "07:00am-11:00am",
                      label: "07:00am - 11:00am",
                    },
                    {
                      value: "05:00pm-09:00pm",
                      label: "05:00pm - 09:00pm",
                    },
                  ]}
                />
              </Space>

              <Space wrap>
                <Select
                  defaultValue="Select by payment"
                  className="product-search-1"
                  onChange={handlePaymentStatusChange}
                  options={[
                    {
                      value: "upi",
                      label: "Pay via UPI",
                    },
                    {
                      value: "card",
                      label: "Debit / credit card",
                    },
                    {
                      value: "cash",
                      label: "Cash on delivery",
                    },
                  ]}
                />
              </Space>
              <Space wrap>
              <DatePicker
            style={{ width: "100%", padding: "5px" }}
            onChange={handleDateChange}
            className="product-search"
            placeholder="Select delivery date"
          />
            </Space>
              <div>
                <button
                  style={{
                    border: "1px solid #0d354f",
                    backgroundColor: "#0d354f",
                    color: "white",
                    borderRadius: "5px",
                    padding: "5px 50px",
                  }}
                  className="but"
                  onClick={filterForcurrentdata}
                >
                  Filter
                </button>
              </div>
            </div>

            <Paper
              sx={{
                width: "100%",
                overflow: "hidden",
                border: "1px solid lightgrey",
                marginRight: "20px",
                marginTop: "30px",
              }}
              className="table-width"
            >
              <TableContainer component={Paper} className="today-order-table">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="tableHead">
                        <span className="head">Date</span>
                      </TableCell>
                      <TableCell className="tableHead">
                        <span className="head">Delivery Date</span>
                      </TableCell>
                      <TableCell className="tableHead">
                        <span className="head">Order id</span>
                      </TableCell>
                      <TableCell className="tableHead">
                        <span className="head">Cleaning type</span>
                      </TableCell>
                      <TableCell className="tableHead">
                        <span className="head">Product</span>
                      </TableCell>
                      <TableCell className="tableHead">
                        <span className="head">Weight</span>
                      </TableCell>
                      <TableCell className="tableHead">
                        <span className="head">Delivery slot</span>
                      </TableCell>
                      <TableCell className="tableHead">
                        <span className="head">Address</span>
                      </TableCell>
                      <TableCell className="tableHead">
                        <span className="head">Payment</span>{" "}
                      </TableCell>
                      <TableCell className="tableHead">
                        <span className="head">Status</span>
                      </TableCell>
                      <TableCell className="tableHead">
                        <span className="head">Assign</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, i) => (
                        <TableRow
                          hover
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            className="tablebody"
                            onClick={() => handleOrderDetails(row._id)}
                          >
                            <p className="width">
                              {moment(row.createdAt).format("Do MMMM YYYY")}
                            </p>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className="tablebody"
                            onClick={() => handleOrderDetails(row._id)}
                          >
                            <p className="width">
                              {moment(row.deliveryDate).format("Do MMMM YYYY")}
                            </p>
                          </TableCell>
                          <TableCell
                            className="tablebody"
                            onClick={() => handleOrderDetails(row._id)}
                          >
                            <p className="width">{row._id&&row._id.slice(-5)}</p>
                          </TableCell>
                          <TableCell
                            className="tablebody"
                            onClick={() => handleOrderDetails(row._id)}
                          >
                            <p className="width"> {row.type}</p>
                          </TableCell>
                          <TableCell
                            className="tablebody"
                            onClick={() => handleOrderDetails(row._id)}
                          >
                            <div
                              style={{ display: "flex", gap: "25px" }}
                              className="width-1"
                            >
                              <div>
                                <img
                                  src={
                                    row.lineItems &&
                                    row.lineItems.length > 0 &&
                                    row.lineItems[0].product &&
                                    row.lineItems[0].product.image
                                      ? `${API_URL}/images/${row.lineItems[0].product.image}`
                                      : "default-image-path.jpg" // Add a default image path if needed
                                  }
                                  style={{ width: "50px", height: "50px" }}
                                  alt="meat"
                                />
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  flexDirection: "column",
                                }}
                              >
                                <p>
                                  {row.lineItems && row.lineItems.length > 0
                                    ? row.lineItems
                                        .map((val, i) =>
                                          val.product && val.product.name
                                            ? val.product.name
                                            : "Unknown product"
                                        )
                                        .join(", ")
                                    : "No products available"}
                                </p>
                                <p>
                                  &#8377;
                                  {row.paymentAmount ? row.paymentAmount.toFixed() : ""}
                                </p>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            className="tablebody"
                            onClick={() => handleOrderDetails(row._id)}
                          >
                            <p>
                              {row.lineItems && row.lineItems.length > 0
                                ? row.lineItems
                                    .map((val, i) => val.quantity)
                                    .join(", ")
                                : "No quantity available"}
                            </p>
                          </TableCell>

                          <TableCell
                            className="tablebody"
                            onClick={() => handleOrderDetails(row._id)}
                          >
                            <p className="width-1">{row.deliverySlot}</p>
                          </TableCell>
                          <TableCell
                            className="tablebody"
                            onClick={() => handleOrderDetails(row._id)}
                          >
                            <p className="d-flex" style={{ gap: "5px" }}>
                              {row.address}
                            </p>
                          </TableCell>
                          <TableCell
                            className="tablebody"
                            onClick={() => handleOrderDetails(row._id)}
                          >
                            <div>
                              <p>{row.paymentMethod}</p>
                              {row.status === "completed" ? (
                                <div
                                  style={{
                                    fontSize: "0.7rem",
                                    color: "limegreen",
                                  }}
                                >
                                  Paid
                                </div>
                              ) : (
                                <div
                                  style={{ fontSize: "0.7rem", color: "red" }}
                                >
                                  Pending
                                </div>
                              )}
                            </div>
                          </TableCell>
                          <TableCell className="tablebody">
                            <Space wrap>
                              <Select
                                placeholder="Pending"
                                variant="borderless"
                                style={{
                                  width: "130px",
                                  height: "35px",
                                  color: "black",
                                }}
                                defaultValue={row.status}
                                className="select-pending pending-o"
                                onChange={(value) =>
                                  handleChangeDelivery(value, row._id)
                                }
                                options={[
                                  {
                                    value: "pending",
                                    label: "Ordered",
                                  },
                                  {
                                    value: "out for delivery",
                                    label: "Out for delivery",
                                  },
                                  {
                                    value: "completed",
                                    label: "Completed",
                                  },
                                ]}
                              />
                            </Space>
                          </TableCell>
                          <TableCell className="tablebody">
                            <Space wrap>
                              <Select
                                placeholder="Pending"
                                variant="borderless"
                                style={{
                                  width: "130px",
                                  height: "35px",
                                  color: "black",
                                }}
                                defaultValue={row.assignedFranchise}
                                className="select-pending pending-o"
                                onChange={(value) =>
                                  handleAssign(value, row._id)
                                }
                                options={fransID.map((id) => ({
                                  value: id,
                                  label: id.slice(-5),
                                }))}
                              />
                            </Space>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                {orderData.length===0&&
                <h3 className="text-center pt-3">No orders found !</h3>}
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  component="div"
                  count={orderData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  className="pt-4 px-4"
                />
               
              </TableContainer>
            </Paper>
            
          </CustomTabPanel>

          {/* CancelledOrder */}

          <CustomTabPanel value={value} index={1}>
            <div
              className="d-flex  product-search-combine"
              style={{ gap: "20px", width: "80%", marginTop: "5px" }}
            >
              <Space wrap>
                <Select
                  defaultValue="select by delivery slot"
                  style={{
                    width: "190px",
                    height: "35px",
                  }}
                  className="product-search-1"
                  onChange={handleDeliverySlotChange}
                  options={[
                    {
                      value: "07:00am-11:00am",
                      label: "07:00am - 11:00am",
                    },
                    {
                      value: "05:00pm-09:00pm",
                      label: "05:00pm - 09:00pm",
                    },
                  ]}
                />
              </Space>

              <Space wrap>
                <Select
                  defaultValue="select by payment"
                  style={{
                    width: "190px",
                    height: "35px",
                  }}
                  className="product-search-1"
                  onChange={handlePaymentStatusChange}
                  options={[
                    {
                      value: "paid",
                      label: "Paid",
                    },
                    {
                      value: "pending",
                      label: "Pending",
                    },
                  ]}
                />
              </Space>

              <div>
                <button
                  style={{
                    border: "1px solid #0d354f",
                    backgroundColor: "#0d354f",
                    color: "white",
                    borderRadius: "5px",
                    padding: "5px 50px",
                  }}
                  className="but"
                  onClick={filterForcanceldata}
                >
                  Filter
                </button>
              </div>
            </div>
            <Paper
              sx={{
                width: "96.5%",
                overflow: "hidden",
                border: "1px solid lightgrey",
                marginRight: "20px",
                marginTop: "30px",
              }}
              className="table-width"
            >
              <TableContainer component={Paper} className="today-order-table">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="tableHead">
                        <span className="head">Date</span>
                      </TableCell>
                      <TableCell className="tableHead">
                        <span className="head">Order id</span>
                      </TableCell>
                      <TableCell className="tableHead">
                        <span className="head">Product</span>
                      </TableCell>
                      <TableCell className="tableHead">
                        <span className="head">Quantity</span>
                      </TableCell>
                      <TableCell className="tableHead">
                        <span className="head">Reason</span>
                      </TableCell>
                      <TableCell className="tableHead">
                        <span className="head">Payment</span>
                      </TableCell>
                      <TableCell className="tableHead">
                        <span className="head">Refund</span>
                      </TableCell>
                      <TableCell className="tableHead">
                        <span className="head">Status</span>
                      </TableCell>
                   
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, i) => (
                        <TableRow
                          hover
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            className="tablebody"
                            onClick={() => handleOrderDetails(row._id)}
                          >
                            <p> {moment(row.date).format("Do MMMM YYYY")}</p>
                          </TableCell>
                          <TableCell className="tablebody"  onClick={() => handleOrderDetails(row._id)}>
                            <p>{row.orderId}</p>
                          </TableCell>
                          <TableCell className="tablebody"  onClick={() => handleOrderDetails(row._id)}>
                            <div style={{ display: "flex", gap: "25px" }}>
                              <div>
                                <img
                                  src={
                                    row.lineItems &&
                                    row.lineItems.length > 0 &&
                                    row.lineItems[0].product &&
                                    row.lineItems[0].product.image
                                      ? `${API_URL}/images/${row.lineItems[0].product.image}`
                                      : "default-image-path.jpg" // Add a default image path if needed
                                  }
                                  style={{ width: "50px", height: "50px" }}
                                  alt="meat"
                                />
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  flexDirection: "column",
                                }}
                              >
                                <p>
                                  {row.lineItems && row.lineItems.length > 0
                                    ? row.lineItems
                                        .map((val, i) =>
                                          val.product && val.product.name
                                            ? val.product.name
                                            : "Unknown product"
                                        )
                                        .join(", ")
                                    : "No products available"}
                                </p>
                                <p>&#8377;{row.paymentAmount}</p>
                              </div>
                            </div>
                          </TableCell>

                          <TableCell className="tablebody" onClick={() => handleOrderDetails(row._id)}>
                            <p>
                              {row.lineItems && row.lineItems.length > 0
                                ? row.lineItems
                                    .map((val, i) => val.quantity)
                                    .join(", ")
                                : "No quantity available"}
                            </p>
                          </TableCell>

                          <TableCell className="tablebody" onClick={() => handleOrderDetails(row._id)}>
                            <p>{row.cancellationReason}</p>
                          </TableCell>
                          <TableCell className="tablebody" onClick={() => handleOrderDetails(row._id)}>
                            <div>
                              <p>{row.paymentMethod}</p>
                              {row.status === "completed" ? (
                                <div
                                  style={{
                                    fontSize: "0.7rem",
                                    color: "limegreen",
                                  }}
                                >
                                  Paid
                                </div>
                              ) : (
                                <div
                                  style={{ fontSize: "0.7rem", color: "red" }}
                                >
                                  Pending
                                </div>
                              )}
                            </div>
                          </TableCell>

                          <TableCell className="tablebody">
                            <div onClick={() => handleOpenL(row)}>
                              <HiReceiptRefund className="refund-icon" />
                            </div>
                          </TableCell>
                          <TableCell className="tablebody" onClick={() => handleOrderDetails(row._id)}>
                            {row.refundStatus}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                {orderData.length===0&&
                <h3 className="text-center pt-3">No orders found !</h3>}
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  component="div"
                  count={orderData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  className="pt-4 px-4"
                />
              </TableContainer>

              {/* Refund */}
              <div>
                <Modal
                  open={refund}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} className="offer">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      <div className="dicount-combine">
                        <div>
                          <h6>Refund</h6>
                        </div>

                        <div>
                          <h6>
                            <MdClear
                              onClick={handleCloseL}
                              style={{ cursor: "pointer" }}
                            />
                          </h6>
                        </div>
                      </div>
                    </Typography>
                    <div className="d-flex discount-meat">
                      <div className="d-flex discount-meatcombine">
                        <div>
                          <img
                            style={{ width: "50px", height: "50px" }}
                            src="/assests/meatimg.png"
                            alt="meat"
                          />
                        </div>
                        <div>
                          <p className="discount-meat-text">Chicken Keema</p>
                          <p className="discount-meat-text">
                            &#8377;{" "}
                            {refundDetails &&
                            refundDetails.lineItems &&
                            refundDetails.lineItems.length > 0
                              ? `${refundDetails.paymentAmount}/${refundDetails.lineItems[0].quantity}`
                              : ""}
                          </p>{" "}
                        </div>
                      </div>
                    </div>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <Row>
                        <Col xs={6}>
                          <div className="refund">
                            <p>
                              Orderid:{" "}
                              <span className="detail-txt-s">
                                {refundDetails ? refundDetails.orderId : ""}
                              </span>
                            </p>
                            <p>
                              Payment mode:{" "}
                              <span className="detail-txt-s">
                                {refundDetails
                                  ? refundDetails.paymentMethod
                                  : ""}
                              </span>
                            </p>
                            {/* <p>
                              UPI id:{" "}
                              <span className="detail-txt-s">john@ybl</span>
                            </p> */}
                          </div>
                        </Col>
                        <Col xs={6}>
                          <div className="refund">
                            <p>
                              Cancelled date:{" "}
                              <span className="detail-txt-s"></span>
                            </p>
                            <p>
                              Reason:{" "}
                              <span className="detail-txt-s">
                                {refundDetails
                                  ? refundDetails.cancellationReason
                                  : ""}
                              </span>
                            </p>
                            <p>
                              Amount:{" "}
                              <span className="detail-txt-s">
                                &#8377;{" "}
                                {refundDetails
                                  ? refundDetails.paymentAmount
                                  : ""}
                              </span>
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Typography>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100px",
                      }}
                    >
                      <button
                        className="refund-button"
                        onClick={() => handleRefund(refundDetails._id)}
                      >
                        Refund
                      </button>
                    </div>
                  </Box>
                </Modal>
              </div>
            </Paper>
          </CustomTabPanel>
        </Box>
      </div>
    </div>
  );
}
