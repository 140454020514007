// import logo from './logo.svg';
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Routing from "./Routing";


// import {Router,Route} from 'react-router-dom';

function App() {
  return (
    
      <>
    <Routing/>
      </>
  );
}

export default App;
