export const ApiEndPoints=(name)=> {
    var list={
        login:"api/user/auth/login",
        postProduct:"api/product",
        getAllproduct:"api/product",
        updateProduct:"api/product",
        getproduct:"api/product",
        getAllorders:"api/order",
        getSingleorder:"api/order",
        patchOrder:"api/order",
        franchise:"api/request",
        postHeroPage:"api/heroPage",
        getHero:"api/heroPage",
        getSingleHero:"api/heroPage",
        patchHero:"api/heroPage",
        getAllReferalBanner:"api/referralBanner",
        patchReferal:"api/referralBanner",
        report:"api/report",
        deleteProduct:"api/product",
        forgetPassword:"api/user/auth/forgotPassword"
    };
    return list[name];
}