import { PhoneOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Input } from "antd";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { IoMdPerson } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from '@mui/material/Snackbar';
import { message, Upload } from "antd";



const SignUp = () => {
  const [signpdata, setSignupData] = useState({
    name: "Admin",
    email: "admin@meatmarket.live",
    password: "Zoho@0109",
    role: "admin",
    phone: 9789983465,
  });



  const [conformPass, setConformPass] = useState("Zoho@0109");
  const navigate=useNavigate()
  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content) => {
    messageApi.open({
      type: 'success',
      content: content,
    });
  };

  const errormessage = (content) => {
    messageApi.open({
      type: 'error',
      content: content,
    });
  };

  const handleSignup = () => {
    if (
      signpdata.email === "" &&
      signpdata.password === "" 
    ) {
      errormessage("Fill all required fields !")
    } else if (signpdata.password !== conformPass) {
      errormessage("Password and Conform password must be same")
    
    } else {
      try {
        const response = axios.post(
          "https://api.meatmarket.live/api/user/auth/signup",
          signpdata,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        successmessage("Signup success")
        navigate("/")
      } catch (error) {
        errormessage("Something went wrong")
      }
    }
  };

  return (
    <>
    {contextHolder}
      <section className="login-bg">
        <Container>
          <div
            className="row d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
              <div className="py-3 login-box">
                <h2 className="title text-center topic">Welcome</h2>
                <p className=" text-center log">Create your account</p>
                <label className="label py-2 sub-t">
                  Email id <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <Input
                  size="large"
                  suffix={<IoMdPerson style={{ color: "grey" }} />}
                  placeholder="Enter your email id"
                  style={{ height: "45px", width: "100%" }}
                  onChange={(e) =>
                    setSignupData({ ...signpdata, email: e.target.value })
                  }
                />
                <br />
                <label className="label py-1 sub-t">
                  Password <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <Input.Password
                  placeholder="Enter your password"
                  style={{ height: "45px", width: "100%" }}
                  onChange={(e) =>
                    setSignupData({ ...signpdata, password: e.target.value })
                  }
                />
                <br />
                <label className="label py-1 sub-t">
                  Confirm Password <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <Input.Password
                  placeholder="Enter your password"
                  style={{ height: "45px", width: "100%" }}
                  onChange={(e) => setConformPass(e.target.value)}
                />
                <br />
                <label className="label py-1 sub-t">
                  Phone Number 
                </label>
                <br />
                <Input
                  size="large"
                  placeholder="Enter your number"
                  style={{ height: "45px", width: "100%" }}
                  onChange={(e) =>
                    setSignupData({
                      ...signpdata,
                      phone: Number(e.target.value),
                    })
                  }
                />
                <div className="text-center log-btn pt-3">
                  <button className="but" onClick={handleSignup}>
                    Create
                  </button>
                  <p className="sub-t-1">
                    Already have an account ? <Link to="/">Login</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default SignUp;
